import { Component, OnInit} from '@angular/core'


interface myData {
  data: Object
}

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.css']
})
export class StudentsComponent implements OnInit {
  
  constructor() { }
  ngOnInit() {
  }
}
