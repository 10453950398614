import { Routes } from '@angular/router';

import { AuthGuardService } from '../../guards/auth-guard.service'

import { AdminLayoutComponent } from './admin-layout.component'

import { HomeComponent } from '../../pages/home/home.component'
import { StudentsComponent } from '../../pages/students/students.component'
import { StudentdetailsComponent } from '../../pages/students/views/studentdetails.component'
import { StudentslistComponent } from '../../pages/students/views/studentslist.component'
import { InstituteComponent } from '../../pages/institute/institute.component'
import { CardsComponent } from '../../pages/cards/cards.component'
import { CoursesComponent } from '../../pages/courses/courses.component'
import { TeachersComponent } from '../../pages/teachers/teachers.component'
import { SchedulesComponent } from '../../pages/schedules/schedules.component'
import { SchoolsComponent } from '../../pages/schools/schools.component'
import { SmsComponent } from '../../pages/sms/sms.component'
import { ReportComponent } from '../../pages/report/report.component'
import { DailyAttensenceComponent } from '../../pages/report/daily-attensence/daily-attensence.component'
import { StudentListByGradeComponent } from '../../pages/report/student-list-by-grade/student-list-by-grade.component'
import { AbsentReportComponent } from '../../pages/report/absent-report/absent-report.component';
import { DailyAttensenceScheduleComponent } from '../../pages/report/daily-attensence-schedule/daily-attensence-schedule.component';
import { StudentDetailsComponent } from '../../pages/report/student-details/student-details.component'
import { ResidentListComponent } from '../../pages/resident-list/resident-list.component'
import { YearSheduleComponent } from '../../pages/year-shedule/year-shedule.component';

export const AdminLayoutRoutes: Routes = [
    {
        path: 'home',
        component: AdminLayoutComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                component: HomeComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: 'members',
                component: StudentsComponent,
                children: [
                    {
                        path: '',
                        component: StudentslistComponent,
                        canActivate: [AuthGuardService]
                    },
                    {
                        path: ':id',
                        component: StudentdetailsComponent,
                        canActivate: [AuthGuardService]
                    }
                ]
            },
            {
                path: 'cards',
                component: CardsComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: 'programs',
                component: CoursesComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: 'schedules',
                component: SchedulesComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: 'month-schedules',
                component: YearSheduleComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: 'attendence',
                component: SchoolsComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: 'center',
                component: InstituteComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: 'instructor',
                component: TeachersComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: 'sms',
                component: SmsComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: 'manageResident',
                component: ResidentListComponent,
                canActivate: [AuthGuardService]
            },  {
                path: 'report',
                component: ReportComponent,
                children: [
                    {
                        path: 'dailyAttendence',
                        component: DailyAttensenceComponent,
                        canActivate: [AuthGuardService]
                    },
                    {
                        path: 'studentListByGrade',
                        component: StudentListByGradeComponent,
                        canActivate: [AuthGuardService]
                    },
                    {
                        path: 'absentReport',
                        component: AbsentReportComponent,
                        canActivate: [AuthGuardService]
                    },
                    {
                        path: 'dailyAttendenceSchedule',
                        component: DailyAttensenceScheduleComponent,
                        canActivate: [AuthGuardService]
                    },
                    {
                        path: 'studentDetails',
                        component: StudentDetailsComponent,
                        canActivate: [AuthGuardService]
                    },
                    {
                        path: '**',
                        redirectTo:'home',
                        canActivate: [AuthGuardService]
                    }
                ]
            }
        ]
    }

];
