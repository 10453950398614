import { Component, Inject, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { apiClient } from '../../data/apiClient'
import { AuthenticationService } from '../../_services/authentication.service'
import { NotifierService } from 'angular-notifier'
import { LoadingScreenService } from '../../_services/loading-screen/loading-screen.service'


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  clickMessage = '';
  username = '';
  password = '';
  instituteId = '';
  instituteName = '';
  public institutes: any[]
  private readonly notifier: NotifierService
  //private readonly loader: LoadingScreenService

  constructor( private notifierService: NotifierService,private router: Router,
     private apiClient: apiClient,private auth: AuthenticationService,
     private loadingService:LoadingScreenService) {
    if (localStorage.getItem("isLogged") === "Yes") {
      this.router.navigateByUrl('/home')
    }else{
      localStorage.setItem('isLogged', 'No')
    }
    if(!localStorage.getItem("baseURL")){
      localStorage.setItem("baseURL", "https://admin-api.wst.lk")
    }
    this.notifier = notifierService
  }

  ngOnInit() {
  }

  onUsernameChange(newValue) {
    this.clickMessage = "";
    this.apiClient.GetInstituteUser({"userId": this.username}).subscribe((result) => {
      if (result["status"] == 200) {
        this.institutes = result["data"] as any[]
        this.instituteId = this.institutes[0].Ins_id
        this.instituteName = this.institutes[0].Name
      } else {
        this.institutes = []
        this.instituteId = ""
      }

    });

  }

  onPasswordChange(newValue) {
    this.clickMessage = ""
  }

  onLoginClicked() {
    this.clickMessage = this.username;
    let params = {
      "username": this.username,
      "password": this.password,
      "instituteId": this.instituteId
    }
    this.auth.login(params).subscribe((result) => {
          if (result["status"] == 200) {
            if(result["data"]){
              this.clickMessage = result["data"].User_Id
            localStorage.setItem("username", this.username)
            localStorage.setItem("instituteID", this.instituteId)
            localStorage.setItem("instituteName", this.instituteName)
            localStorage.setItem('isLogged', 'Yes')
            
            this.notifier.notify( 'success','Welcome To NISALA ARANA!')
            this.notifier.notify( 'success','You Loged As '+this.username)
            this.router.navigateByUrl('/home')
            }
            return
          }
          else{
            this.notifier.notify( 'error',result["message"])
            this.clickMessage = result["message"]
          }
        },
        error => {
          console.log("Error", error);
        }
      );
  }
}
