import { Component, OnInit, ViewChild } from '@angular/core'
import { apiClient } from '../../../data/apiClient'
import moment from 'moment-timezone'
import { NotifierService } from 'angular-notifier'

@Component({
  selector: 'app-daily-attensence',
  templateUrl: './daily-attensence.component.html',
  styleUrls: ['./daily-attensence.component.css']
})
export class DailyAttensenceComponent implements OnInit {

  courses = []
  model: any = {}
  optionsModel: number[]
  dropdownList = []
  selectedItems = []
  courseDropdownSettings = {}
  subjectDropdownSettings = {}
  scheduleDropdownSettings = {}
  course = []

  courseType: any
  courseTypes: any
  attendenceTypes: any
  courseTypeDropdownSettings = {}
  attendenceTypeDropdownSettings = {}

  courseList = []
  startDate = ""
  endDate = ""
  reportData = { startDate: "", endDate: "", CourseID: "", Insid: "", ProgramType: "" }
  attendenceList: any = []
  filteredAttendenceList: any = []
  className: any = ""
  isAdminUser: any
  isSuperUser: any
  filterType:any


  private readonly notifier: NotifierService

  constructor(private notifierService: NotifierService, private api: apiClient) {
    this.notifier = notifierService
  }

  @ViewChild('#printArea') public printArea: any

  ngOnInit() {
    this.isAdminUser = localStorage.getItem("isAdminUser")
    this.isSuperUser = localStorage.getItem("isSuperUser")
    this.className = localStorage.getItem('instituteName')
    this.startDate = moment().tz("UTC").format('YYYY-MM-DD')
    this.endDate = moment().tz("UTC").format('YYYY-MM-DD')

    /*     this.subjectDropdownSettings = {
          singleSelection: false,
          idField: 'TeacherID',
          textField: 'Name',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 1,
          allowSearchFilter: true
        }; */


    this.courseDropdownSettings = {
      singleSelection: true,
      idField: 'CourseID',
      textField: 'Course',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.courseTypeDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.attendenceTypeDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.courseTypes = [
      { id: "S8", text: "Season 8" },
      { id: "None", text: "Non Season 8" },
      { id: "Resident", text: "Resident " },
      { id: "All", text: "All " }
    ]

    this.attendenceTypes = [
      { id: "Present", text: "Present Member List" },
      { id: "Left", text: "Left Member List" }
    ]
    this.loadCourseDetails()

  }

  loadCourseDetails() {
    let params = {
      TeacherID: localStorage.getItem("teacherID"),
      instituteID: localStorage.getItem("instituteID"),
      isAdminUser: this.isAdminUser,
      isSuperUser: this.isSuperUser
    }

    this.api.getCourseList(params).subscribe((data) => {
      if (data["status"] == 200) {
        this.course = data["data"][0] as any[];
        this.course.forEach(element => {
          element.Course = element.Subject + " " + element.Grade
        })
      } else {
        this.course = [];
      }
    })
  }


  /*  onSubjectSelect(item: any) {
     this.teacher.some(teacher => teacher.TeacherID === item.TeacherID) ? console.log(item) : this.teacher.push(item)
   }
 
   onSelectAllSubjects(items: any) {
     this.teacher = items
     this.api.getCoursesOfTeacher(this.teacher).subscribe(data => {
       this.course = data["data"]
     })
   }
 
   onDeSelectAllSubjects() {
     this.teacher = [];
     this.course = []
   }
 
   onDeSelectSubject(items: any) {
 
     const index = this.teacher.findIndex(x => x.TeacherID === items.TeacherID);
     if (index !== undefined) this.teacher.splice(index, 1);
 
     this.api.getCoursesOfTeacher(this.teacher).subscribe(data => {
       this.course = data["data"]
     })
 
   }
  */

  /* onCourseSelect(item: any) {
 
     this.courseList.some(course => course.CourseID === item.CourseID) ? console.log(item) : this.courseList.push(item)
 
   } */

  onCourseSelect(item: any) {
    this.courseList = []
    this.courseList.push(item)
  }

  /* onSelectAllCourse(items: any) {
    this.courseList = items
  }

  onDeSelectAllCourses() {
    this.courseList = []
  } */

  onDeSelectCourse(items: any) {
    const index = this.courseList.findIndex(x => x.CourseID === items.CourseID);
    if (index !== undefined) this.courseList.splice(index, 1);
  }

  onCourseTypeSelect(item: any) {
    this.courseType = item.id
  }

  onDeSelectCourseType(items: any) {
    this.courseType = null
  }


  onAttendenceTypeSelect(item: any) {
    if(!this.filteredAttendenceList){
      return
    }

    if(item.id == "Present"){
      this.filteredAttendenceList = this.attendenceList
      this.filterType = item.id
      this.filteredAttendenceList = this.filteredAttendenceList.filter(element=> element.OutTime == 'N/A')
    }

    if(item.id == "Left"){
      this.filteredAttendenceList = this.attendenceList
      this.filterType = item.id
      this.filteredAttendenceList = this.filteredAttendenceList.filter(element=> element.OutTime != 'N/A')
    }

  }

  onDeSelectAttendenceType(items: any) {
    this.filteredAttendenceList = this.attendenceList
    this.filterType = ''
  }


  valueChangeStartDate(startDate: any) {
    this.startDate = startDate
  }

  valueChangeEndDate(endDate: any) {
    this.endDate = endDate
  }

  generateReport() {
    this.reportData.startDate = this.startDate
    this.reportData.endDate = this.endDate
    this.reportData.CourseID = this.courseList[0].CourseID
    this.reportData.Insid = localStorage.getItem("instituteID")
    this.reportData.ProgramType = this.courseType

    if (!this.reportData.CourseID) {
      this.notifier.notify('error', 'Please Select Program')
      return
    }

    if (!this.reportData.ProgramType) {
      this.notifier.notify('error', 'Please Select Program Type')
      return
    }

    this.api.GetDailyAttendenceRepot(this.reportData).subscribe((resp) => {
      if (resp["status"] == 200) {
        this.attendenceList = resp["data"][0]
        this.attendenceList.forEach(element => {
          element.AttendDate = moment(element.AttendTime).format('YYYY-MM-DD')
          element.OutTime = element.OutTime ? moment(element.OutTime,['MM DD yyyy HH:mm','yyyy-MM-DD HH:mm:ss']).format('HH:mm:ss') : "N/A"
        })
        this.filteredAttendenceList = this.attendenceList
      } else {
        this.attendenceList = [];
      }
    })
  }

}
