import { Component, OnInit } from '@angular/core'
import { apiClient } from '../../../data/apiClient'
import moment from 'moment-timezone'

@Component({
  selector: 'app-student-list-by-grade',
  templateUrl: './student-list-by-grade.component.html',
  styleUrls: ['./student-list-by-grade.component.css']
})
export class StudentListByGradeComponent implements OnInit {
  constructor(private api: apiClient) { }
  courses = []
  model: any = {}
  optionsModel: number[]
  dropdownList = []
  selectedItems = []
  courseDropdownSettings = {}
  subjectDropdownSettings = {}
  course = []
  courseList = []
  startDate = ""
  endDate = ""
  reportData = {  CourseID: "", Insid: "" , InsName: ""}
  studentList: any = []
  className:any = ""
  isAdminUser:any
  isSuperUser:any

  ngOnInit() {
    this.className = localStorage.getItem('instituteName')
    this.isAdminUser = localStorage.getItem("isAdminUser")
    this.isSuperUser = localStorage.getItem("isSuperUser")
    /* this.startDate = moment().tz("UTC").format('YYYY-MM-DD')
    this.endDate = moment().tz("UTC").format('YYYY-MM-DD') */

    /*     this.subjectDropdownSettings = {
          singleSelection: false,
          idField: 'TeacherID',
          textField: 'Name',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 1,
          allowSearchFilter: true
        }; */

    this.courseDropdownSettings = {
      singleSelection: true,
      idField: 'CourseID',
      textField: 'Course',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.loadCourseDetails()

  }

  loadCourseDetails() {
    let params = {
      TeacherID: localStorage.getItem("teacherID"),
      instituteID: localStorage.getItem("instituteID"),
      isAdminUser: this.isAdminUser,
      isSuperUser: this.isSuperUser
    }

    this.api.getCourseList(params).subscribe((data) => {
      if (data["status"] == 200) {
        this.course = data["data"][0] as any[];
        this.course.forEach(element => {
          element.Course = element.Subject + " " + element.Grade
        })
      } else {
        this.course = [];
      }
    })
  }

  onSubmit() {

  }

  /*  onSubjectSelect(item: any) {
     this.teacher.some(teacher => teacher.TeacherID === item.TeacherID) ? console.log(item) : this.teacher.push(item)
   }
 
   onSelectAllSubjects(items: any) {
     this.teacher = items
     this.api.getCoursesOfTeacher(this.teacher).subscribe(data => {
       this.course = data["data"]
     })
   }
 
   onDeSelectAllSubjects() {
     this.teacher = [];
     this.course = []
   }
 
   onDeSelectSubject(items: any) {
 
     const index = this.teacher.findIndex(x => x.TeacherID === items.TeacherID);
     if (index !== undefined) this.teacher.splice(index, 1);
 
     this.api.getCoursesOfTeacher(this.teacher).subscribe(data => {
       this.course = data["data"]
     })
 
   }
  */

  /* onCourseSelect(item: any) {
 
     this.courseList.some(course => course.CourseID === item.CourseID) ? console.log(item) : this.courseList.push(item)
 
   } */

  onCourseSelect(item: any) {
    this.courseList = []
    this.courseList.push(item)
  }

  onSelectAllCourse(items: any) {
    this.courseList = items
  }

  onDeSelectAllCourses() {
    this.courseList = []
  }

  onDeSelectCourse(items: any) {
    const index = this.courseList.findIndex(x => x.CourseID === items.CourseID);
    if (index !== undefined) this.courseList.splice(index, 1);
  }


  valueChangeStartDate(startDate: any) {
    this.startDate = startDate
  }

  valueChangeEndDate(endDate: any) {
    this.endDate = endDate
  }

  generateReport() {
    this.reportData.CourseID = this.courseList[0].CourseID
    this.reportData.Insid = localStorage.getItem("instituteID")

    if (!this.reportData.CourseID) {
      return
    }
    console.log(this.reportData)

    this.api.GetStudentGradeList(this.reportData).subscribe((resp) => {
      if (resp["status"] == 200) {
        this.studentList = resp["data"][0] as any[];
      } else {
        this.studentList = [];
      }
      
      console.log(this.studentList)
    })
  }

}
