import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod'

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    baseURL: any = ""

    constructor(public http: HttpClient) {
        this.baseURL = environment.base_url
    }

    login(params) {
        return this.http.post(this.baseURL + '/api/login', params).map(user => {
            if (user && user["token"]) {
                localStorage.setItem("teacherID", user["data"].TeacherID)
                localStorage.setItem("isSuperUser", user["data"].SuperUser)
                if(user["data"].AdminUser){
                    localStorage.setItem("isAdminUser", "Y")
                }else{
                    localStorage.setItem("isAdminUser", "N")
                }
                
                localStorage.setItem('token', user["token"])
            }

            return user;
        });
    }

    logout() {
        localStorage.clear()
        localStorage.setItem('isLogged', 'No')
    }
}