import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router'
import { apiClient } from '../../data/apiClient'
import { formatDate } from '@angular/common'
import { NotifierService } from 'angular-notifier'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { LoadingScreenService } from '../../_services/loading-screen/loading-screen.service'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { element } from '@angular/core/src/render3'

@Component({
  selector: 'app-teachers',
  templateUrl: './teachers.component.html',
  styleUrls: ['./teachers.component.css']
})
export class TeachersComponent implements OnInit {

  private readonly notifier: NotifierService
  //private readonly loader: LoadingScreenService
  public modalRef: BsModalRef

  model: any = {}
  selectedTeacher: any = {}
  showItem = false
  add_success = false
  add_error = false
  public teachers: any[]
  public institutes: any[]
  isSuperUser: any = ""
  isAdminUser: any = ""
  p: any
  page: any
  pageSize: any
  pager: any = []
  length: any

  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort
  displayedColumns: string[] = ['TeacherID', 'TecFristName', 'TecLastName', 'Type', 'Joindate', 'Status', 'Options']
  dataSource: any

  constructor(private modalService: BsModalService, private notifierService: NotifierService, private http: HttpClient, private router: Router,
    private api: apiClient,
    private loadingService: LoadingScreenService) {
    this.notifier = notifierService
  }


  ngOnInit() {
    this.page = 1
    this.pageSize = 15
    this.isAdminUser = localStorage.getItem("isAdminUser")
    this.isSuperUser = localStorage.getItem("isSuperUser")
    this.loadInstitutes()
    this.loadteachers()
  }


  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }
  validateFilter(filterValue: string) {
    this.teachers.filter(element => {
      if (
        element.ContactNo.toLowerCase().match(filterValue.toLowerCase()) ||
        element.NIC.toLowerCase().match(filterValue.toLowerCase())
        ) {
          console.log(element)
      }
    })
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  loadteachers() {
    let params = {
      TeacherID: localStorage.getItem("teacherID"),
      instituteID: localStorage.getItem("instituteID"),
      isAdminUser: this.isAdminUser,
      isSuperUser: this.isSuperUser,
      pageNo: this.page,
      size: this.pageSize,
    }

    this.api.getTeachersList(params).subscribe((data) => {
      if (data["status"] == 200) {
        this.teachers = data["data"][0] as any[];
        this.pager = data["pager"]
        this.dataSource = new MatTableDataSource(this.teachers)
        this.length = this.pager.total
        this.dataSource.sort = this.sort
      } else {
        this.teachers = [];
      }
    })
  }
  getDate(date) {
    return formatDate(date, 'yyyy/MM/dd', 'en')
  }

  loadInstitutes() {
    let params = {
      pageNo: 1,
      size: 500,
      isAdminUser: this.isAdminUser,
      instituteID: localStorage.getItem("instituteID")
    }

    this.api.GetInstituteList(params).subscribe(data => {
      if (data["status"] == 200) {
        this.institutes = data["data"][0] as any[];
      } else {
        this.institutes = [];
      }
    })
  }

  onPageEvent(event: PageEvent) {
    this.page = event.pageIndex + 1
    this.pageSize = event.pageSize
    this.loadteachers()
  }


  addTeacher() {
    this.add_success = false;
    this.add_error = false;
    if (!this.model.instituteID) {
      this.notifier.notify("error", "Select Institute!")
      return
    }

    if (this.model.ActiveStatus == null) {
      this.notifier.notify("error", "Check Active Status!")
      return
    }

    if (!this.model.JoinDate) {
      this.notifier.notify("error", "Add Join Date!")
      return
    }

    if (!this.model.FirstName.match(/\w+/g) || !this.model.FirstName) {
      this.notifier.notify("error", "Enter First Name!")
      return
    }
    if (!this.model.LastName.match(/\w+/g) || !this.model.LastName) {
      this.notifier.notify("error", "Enter First Name!")
      return
    }

    if (this.model.ActiveStatus == true) {
      this.model.ActiveStatus = 1;
    }
    else {
      this.model.ActiveStatus = 0;
    }
    if (this.model.ActiveStatus == null) {
      return;
    }
    if (this.model.ActiveStatus == true) {
      this.model.ActiveStatus = 1;
    }
    else {
      this.model.ActiveStatus = 0;
    }

    this.model.instituteID = localStorage.getItem("instituteID")
    this.model.userId = localStorage.getItem("username")

    this.api.addTeacher(this.model).toPromise().then((res) => {
      if (res["status"] == 200) {
        this.loadteachers()
        this.modalRef.hide()
        this.notifier.notify("success", "New Instructor Successfully Added!")
      } else {
        this.notifier.notify("error", "Error!")
        this.add_error = true;
      }
    })
  }

  editTeacher() {
    this.selectedTeacher.userId = localStorage.getItem("username")
    if (this.selectedTeacher.ActiveStatus == true) {
      this.selectedTeacher.ActiveStatus = 1;
    }
    else {
      this.selectedTeacher.ActiveStatus = 0;
    }

    this.api.editTeacher(this.selectedTeacher).toPromise().then((resp) => {
      if (resp["status"] == 200) {
        this.add_success = true
        this.loadteachers()
        this.modalRef.hide()
        this.notifier.notify("success", "Instructor Successfully Editeded!")
      } else {
        this.notifier.notify("success", "Error!")
        this.add_error = true;
      }
    })
  }

  selectTeacher(index) {
    this.selectedTeacher = {}
    console.log(index)
    let params = {
      teacherID: index,
      instituteID: localStorage.getItem("instituteID")
    }

    this.api.getTeachere(params).subscribe((res) => {

      this.selectedTeacher = res["data"][0]
      this.selectedTeacher.Joindate = this.getDate(this.selectedTeacher.Joindate)
    })
  }

}
