import { Routes, RouterModule } from '@angular/router'

import { AuthGuardService } from './guards/auth-guard.service'

import { LoginComponent } from './pages/login/login.component'
import { NgModule } from '@angular/core'


const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full'
  },{
    path:'**',
    redirectTo: 'login',
    pathMatch: 'full'
  }
]


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
