import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { SidebarComponent } from './sidebar.component'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { CollapseModule } from 'ngx-bootstrap/collapse'


@NgModule({
    imports: [ RouterModule, CommonModule ,NgbModule,CollapseModule],
    declarations: [ SidebarComponent ],
    exports: [ SidebarComponent]
})

export class SidebarModule {}
