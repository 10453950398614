import { NgModule } from '@angular/core'
import { CommonModule, DatePipe, LocationStrategy, HashLocationStrategy } from '@angular/common'

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown'
import { NguiDatetimePickerModule } from '@ngui/datetime-picker'
import { CalendarModule } from 'primeng/calendar'
import { Ng2SearchPipeModule } from 'ng2-search-filter'
import { Ng2OrderModule } from 'ng2-order-pipe'
import { NgxPaginationModule } from 'ngx-pagination'
import { ChartsModule } from 'ng2-charts'
import { FormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { CollapseModule } from 'ngx-bootstrap/collapse'
import { TabsModule } from 'ngx-bootstrap/tabs'


import { StudentsComponent } from './students.component'
import { StudentdetailsComponent } from './views/studentdetails.component'
import { StudentslistComponent } from './views/studentslist.component'
import { RouterModule } from '@angular/router'
import { NgxPrintModule } from 'ngx-print'
import { MatTableModule } from '@angular/material/table'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSortModule } from '@angular/material/sort'
import { MatSelectModule } from '@angular/material'
import { NgxPaginateModule } from 'ngx-paginate'
import { NgxMaskModule } from 'ngx-mask'


@NgModule({
  declarations: [
      StudentdetailsComponent,
      StudentsComponent,
      StudentslistComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    RouterModule,
    CollapseModule,
    TabsModule,
    Ng2SearchPipeModule, 
    Ng2OrderModule, 
    NgxPaginationModule,
    ChartsModule,
    NgxPrintModule,
    NguiDatetimePickerModule,
    NgMultiSelectDropDownModule,
    CalendarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSelectModule,
    NgxPaginateModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [DatePipe,{provide: LocationStrategy, useClass: HashLocationStrategy}]
})
export class StudentsModule { }
