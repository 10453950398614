import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoadingScreenService } from '../../_services/loading-screen/loading-screen.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  Subject: any = [];
  AttendedCount: any = [];
  RegisteredStudents: any = [];
  public dashboardSummary: any = [
    'TotalStudents',
    'TotalCources',
    'TotalTeachers',
    'TotalInstitutes',
  ];
  //  TotalStudents = {};

  public barChartData: any[] = [
    { data: [], label: 'Series B', stack: '1' },
    { data: [], label: 'Series C', stack: '2' }

  ];
  public barChartLabels: any[] = ["", "", "", "", "", "", "", "", "", ""];
  public barChartOptions: any = {
    responsive: true,
    scales: {
      xAxes: [{
        stacked: true,
        ticks: {
          fontSize: 8
        }
      }],
      yAxes: [{
        stacked: true,
        ticks: {
          fontSize: 8
        }
      }]
    }
  };
  public barChartType: string = 'bar';

  public pieChartLabels: string[] = ['', '', ''];
  public pieChartData: number[] = [, , , , , , , , , , ,];
  public pieChartType: string = 'pie';

  constructor(private http: HttpClient, private loadingService: LoadingScreenService) {

  }

  ngOnInit() {
    this.http.post(localStorage.getItem("baseURL") + '/api/nisalaapi/dashboard/today-attendance', {
      "InstituteId": localStorage.getItem("instituteID"),
      "TeacherId": localStorage.getItem("teacherID"),
    })
      .toPromise()
      .then((response) => {
        var index = 0;
        this.pieChartLabels.length = 0;
        for (let result of response["data"]) {
          this.barChartLabels[index] = result.Subject;
          this.AttendedCount.push(result.AttendedCount);
          this.RegisteredStudents.push(result.RegisteredStudents);
          this.pieChartLabels[index] = result.Subject;
          index++;
        }

        this.pieChartData = this.RegisteredStudents;

        this.barChartData = [
          { data: this.AttendedCount, label: 'Attended Count', stack: '1' },
          { data: this.RegisteredStudents, label: 'Registered Members', stack: '2' }
        ];

      });



    this.http.post(localStorage.getItem("baseURL") + '/api/nisalaapi/dashboard/summary', {
      "InstituteId": localStorage.getItem("instituteID"),
      "TeacherId": 1
    })
      .toPromise()
      .then((response) => {

        this.dashboardSummary = response["data"];



      });




  }



  // events
  public chartClicked(e: any): void {

  }

  public chartHovered(e: any): void {

  }

}
