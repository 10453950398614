import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let isLogged = localStorage.getItem('isLogged')
        let token = localStorage.getItem('token')
        if (isLogged === "Yes" && token) {
            request = request.clone({
                headers: request.headers.append('Authorization', `Bearer ${token}`)
            });
        }
        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.append('Content-Type', 'application/json') })
        }

        request = request.clone({ headers: request.headers.append('Accept', 'application/json') })
        request = request.clone({ headers: request.headers.append('Access-Control-Allow-Origin', '*') })

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                return event;
            }))
    }
}