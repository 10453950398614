import { Component, OnInit } from '@angular/core'
import Stepper from 'bs-stepper'
import { apiClient } from '../../data/apiClient'
import { NotifierService } from 'angular-notifier'
import * as XLSX from 'xlsx'
import * as moment from 'moment-timezone'
import { element } from '@angular/core/src/render3'

type AOA = any[][]
@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.css']
})
export class SmsComponent implements OnInit {
  private stepper: Stepper;
  htmlContent: any
  editorConfig: any
  teacherDropdownSettings: any
  coursesDropdownSettings: any
  GroupTypeDropdownSettings: any
  AudianceTypeDropdownSettings: any
  isSuperUser: any = ""
  isAdminUser: any = ""
  teacher: any = []
  teacherList: any[]
  course: any = []
  courseList: any = []
  GroupType: any = []
  GroupTypes: any = []
  UserListType: any
  AudianceType: any = [{ id: null }]
  AudianceTypes: any = []
  studentList: any = []
  studentListFiltered: any = []
  studentListSelected: any = []
  studentListRejected: any = []
  private readonly notifier: NotifierService
  selectAll: boolean = false
  isCreditZero: boolean = false
  creditLimit: any
  methodSelected: any
  stepperState: any
  costForSendSms: any
  msgCst: any
  msgCnt: number
  selectedAudience: number
  rejectedAudience: number

  isExcelFile: boolean = false
  data: any

  constructor(private notifierService: NotifierService, private api: apiClient) {
    this.msgCst = 2
    this.editorConfig = {
      "editable": true,
      "spellcheck": true,
      "height": "200px",
      "minHeight": "50px",
      "width": "auto",
      "minWidth": "350px",
      "translate": "yes",
      "enableToolbar": false,
      "showToolbar": false,
      "placeholder": "Enter text here..."
    }
    this.teacherDropdownSettings = {
      singleSelection: false,
      idField: 'TeacherID',
      textField: 'Name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    }
    this.coursesDropdownSettings = {
      singleSelection: false,
      idField: 'CourseID',
      textField: 'CourseName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    }
    this.GroupTypeDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    }
    this.AudianceTypeDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    }
    this.notifier = notifierService
    this.GroupTypes = [
      { id: "None", text: "None" },
      { id: "Meditation", text: "Meditation " },
      { id: "SEASON 8", text: "Season 8" },
      { id: "Dhamma", text: "Dhamma " },
      { id: "Card", text: "Card " },
      { id: "Donation", text: "Donation " },
      { id: "Resident", text: "Resident " }
    ]
    this.AudianceTypes = [
      { id: "Parent", text: "Emergency" },
      { id: "Student", text: "Member" }
    ]
  }

  ngOnInit() {
    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true
    })
    this.isAdminUser = localStorage.getItem("isAdminUser")
    this.isSuperUser = localStorage.getItem("isSuperUser")
    this.loadteacherList()
    this.checkAvailableCredilimit()
    this.loadSmsTemplate()
  }
  checkAvailableCredilimit() {
    let params = {
      instituteID: localStorage.getItem("instituteID")
    }

    this.api.getCreditLimit(params).subscribe((data) => {
      if (data["status"] == 200) {
        if (!(data["data"][0].length > 0)) {
          this.creditLimit = 0
        } else {
          this.creditLimit = data["data"][0][0].Balance
        }
        if (this.creditLimit <= 2) {
          this.notifier.notify("error", "You have not Suficient Credit Limit! update Your Credit Limit")
          this.isCreditZero = true
        } else {
          this.isCreditZero = false
        }
      } else {
        this.creditLimit = ''
      }
    })
  }

  loadSmsTemplate() {

  }
  applyFilter(filterValue: string) {
    if (!(filterValue.length > 0)) {
      this.studentListFiltered = []
      this.studentListFiltered = this.studentList
    } else {
      this.studentListFiltered = []
      this.studentListFiltered = this.studentList.filter(element => {
        if (element.Name) {
          if (
            element.STSerNo.toLowerCase().match(filterValue.trim().toLowerCase()) ||
            element.Name.toLowerCase().match(filterValue.trim().toLowerCase()) ||
            element.ParentMobile.toLowerCase().match(filterValue.trim().toLowerCase()) ||
            element.StudentMobile.toLowerCase().match(filterValue.trim().toLowerCase())
          ) {
            return element
          }
        }
      })
    }
  }

  onFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>(evt.target)
    if (target.files.length === 0) {
      this.notifier.notify("error", "File Not Selected")
      return
    }
    if (target.files.length !== 1) {
      this.notifier.notify("error", "Select one file")
      throw new Error('Cannot use multiple files')
    }

    this.isExcelFile = true
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' })
      const wsname: string = wb.SheetNames[0]
      const ws: XLSX.WorkSheet = wb.Sheets[wsname]
      let dj = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }))
      if (!dj[0][0].toLowerCase().match("phoneno") || !dj[0][1].toLowerCase().match("message")) {
        this.notifier.notify("error", "Headers are not match! Default headers are PhoneNo and Message")
        return
      }
      this.data = []
      for (var i = 1; i <= dj.length; i++) {
        if (dj[i][0]) {
          let par = {
            PhoneNo: dj[i][0],
            Message: dj[i][1]
          }
          this.data.push(par)
        }
      }
    };
    reader.readAsBinaryString(target.files[0]);
  }

  loadteacherList() {
    let params = {
      pageNo: 1,
      size: 5000,
      TeacherID: localStorage.getItem("teacherID"),
      instituteID: localStorage.getItem("instituteID"),
      isAdminUser: this.isAdminUser,
      isSuperUser: this.isSuperUser
    }

    this.api.getTeachersList(params).subscribe((data) => {
      if (data["status"] == 200) {
        data["data"][0].forEach(element => {
          element.Name = element.TecFristName + " " + element.TecLastName
        })
        this.teacherList = data["data"][0] as any[];
      } else {
        this.teacherList = [];
      }
    })
  }

  next(id) {
    this.stepperState = id + 1
    this.stepper.next()
  }

  back() {
    this.stepperState -= this.stepperState
    this.stepper.previous()
  }

  setMethord(method) {
    this.msgCnt = 0
    this.studentListSelected = []
    this.htmlContent = ''
    this.methodSelected = method
    this.next(1)
  }

  setUserList(method) {
    this.UserListType = method
  }

  onTeacherSelect(item: any) {
    if (!(this.teacher.length > 0)) {
      this.teacher.push(item)
      this.loadCourseListByTeachers()
    } else {
      this.teacher.some(teacher => teacher.TeacherID === item.TeacherID) ? console.log(item) : this.teacher.push(item)
      this.loadCourseListByTeachers()
    }
  }

  loadCourseListByTeachers() {
    let params = {
      teachers: this.teacher,
      instituteID: localStorage.getItem("instituteID")
    }
    this.api.getCoursesListByTeachers(params).subscribe(resp => {
      if (resp["status"] === 200) {
        resp["data"].forEach(element => {
          element.CourseName = element.Subject + "-" + element.Grade + "-" + element.TeacherName
        })
        this.courseList = resp["data"] as any[]
      }
    })
  }

  onSelectAllTeachers(items: any) {
    this.teacher = items
    this.loadCourseListByTeachers()
  }

  onDeSelectAllTeachers() {
    this.teacher = []
    this.courseList = []
    this.course = []
  }

  onDeSelectTeacher(items: any) {

    const index = this.teacher.findIndex(x => x.TeacherID === items.TeacherID);
    if (index !== undefined) this.teacher.splice(index, 1);

    if (this.teacher.length > 0) {
      this.loadCourseListByTeachers()
    } else {
      this.courseList = []
    }

  }

  onCourseSelect(item: any) {
    if (!(this.course.length > 0)) {
      this.course.push(item)
    } else {
      this.course.some(course => course.CourseID === item.CourseID) ? console.log(item) : this.course.push(item)
    }
  }

  onSelectAllCourses(items: any) {
    this.course = items
  }

  onDeSelectAllCourses() {
    this.course = []
  }

  onDeSelectCourse(items: any) {
    const index = this.course.findIndex(x => x.CourseID === items.CourseID);
    if (index !== undefined) this.course.splice(index, 1);
  }

  onGroupTypeSelect(item: any) {
    console.log(item)
    if (!(this.GroupType.length > 0)) {
      this.GroupType.push(item)
    } else {
      this.GroupType.some(GroupType => GroupType === item) ? console.log(item) : this.GroupType.push(item)
    }
  }

  onSelectAllGroupTypes(items: any) {
    console.log(items)
    this.GroupType = items
  }

  onDeSelectAllGroupTypes() {
    this.GroupType = []
  }

  onDeSelectGroupType(items: any) {
    const index = this.GroupType.findIndex(x => x === items);
    if (index !== undefined) this.GroupType.splice(index, 1);
  }

  onAudianceTypeSelect(item: any) {
    this.AudianceType = ''
    this.AudianceTypes.some(AudianceType => AudianceType === item) ? console.log(item) : this.AudianceType = item
  }

  onDeSelectAudianceType(items: any) {
    /* const index = this.GroupType.findIndex(x => x === items);
    if (index !== undefined) this.GroupType.splice(index, 1); */
    this.AudianceType = ''
  }

  searchStudents() {
    this.selectAll = false
    this.isExcelFile = false
    let params = {
      course: this.course,
      GroupType: this.GroupType,
      Ins_id: localStorage.getItem("instituteID")
    }

    if (!(this.teacher.length > 0)) {
      this.notifier.notify("error", "Select a instructor")
      return
    }

    if (!(this.course.length > 0)) {
      this.notifier.notify("error", "Select a program")
      return
    }

    if (!(this.GroupType.length > 0)) {
      this.notifier.notify("error", "Select a group type")
      return
    }

    this.api.getStudentListByTeacherCourse(params).subscribe(resp => {
      if (resp["status"] === 200) {
        this.studentList = resp["data"] as any[]

        this.studentListFiltered = this.studentList
      } else {
        this.studentList = resp["data"] as any[]
      }
    })
  }

  selectAllStudents() {
    if (!this.selectAll) {
      this.studentList.forEach(element => {
        element.isSelect = true
      })
    } else {
      this.studentList.forEach(element => {
        element.isSelect = false
      })
    }
  }

  getSelectedList() {
    this.studentListSelected = []
    this.studentListRejected = []
    if (!(this.teacher.length > 0) && !this.isExcelFile) {
      this.notifier.notify("error", "Select a instructor")
      return
    }
    if (!(this.course.length > 0) && !this.isExcelFile) {
      this.notifier.notify("error", "Select a program")
      return
    }
    if (!(this.GroupType.length > 0) && !this.isExcelFile) {
      this.notifier.notify("error", "Select a type")
      return
    }
    if (!this.AudianceType.id && !this.isExcelFile) {
      this.notifier.notify("error", "Select Audiance Type!")
      return
    }

    if (!this.isExcelFile) {
      this.studentList.map(student => {
        if (student.isSelect) {
          if (this.AudianceType.id === 'Parent') {
            if (student.ParentMobile.match(/^[9][4][7]\d{8}$/g) && !(student.ParentMobile.match(/0{5,}/g)) && !(student.ParentMobile.match(/7{5,}/g))) {
              let param = {
                STSerNo: student.STSerNo,
                Name: student.Name,
                Mobile: student.ParentMobile
              }
              this.studentListSelected.push(param)
            } else {
              let param = {
                STSerNo: student.STSerNo,
                Name: student.Name,
                Mobile: student.ParentMobile
              }
              this.studentListRejected.push(param)
            }
          }
          if (this.AudianceType.id === 'Student') {
            if (student.StudentMobile.match(/^[9][4][7]\d{8}$/g) && !(student.StudentMobile.match(/0{5,}/g)) && !(student.StudentMobile.match(/7{5,}/g))) {
              let param = {
                STSerNo: student.STSerNo,
                Name: student.Name,
                Mobile: student.StudentMobile
              }
              this.studentListSelected.push(param)
            } else {
              let param = {
                STSerNo: student.STSerNo,
                Name: student.Name,
                Mobile: student.ParentMobile
              }
              this.studentListRejected.push(param)
            }
          }
        }
      })
      if (!(this.studentListSelected.length > 0)) {
        this.notifier.notify("error", "Select Minimum One Member! Please Check Mobile Number With Correct Format")
        return
      }
    } else {
      this.data.map(element => {
        if (element.PhoneNo) {
          if (element.PhoneNo.toString().match(/^[9][4][7]\d{8}$/g) && !(element.PhoneNo.toString().match(/0{5,}/g)) && !(element.PhoneNo.toString().match(/7{5,}/g))) {
            let param = {
              PhoneNo: element.PhoneNo,
              Message: element.Message
            }
            this.studentListSelected.push(param)
          } else {
            let param = {
              PhoneNo: element.PhoneNo,
              Message: element.Message
            }
            this.studentListRejected.push(param)
          }
        }
      })
      this.htmlContent = this.data[0].Message
    }
    this.next(2)

  }

  validateSmsCount() {
    if (!this.htmlContent || !(this.htmlContent.length > 0)) {
      this.notifier.notify("error", "Set Message!")
      return
    }
    this.msgCnt = Math.ceil(this.htmlContent.length / 160)
    this.selectedAudience = this.studentListSelected.length
    this.rejectedAudience = this.studentListRejected.length
    this.costForSendSms = this.msgCst * this.msgCnt * this.selectedAudience

    if (this.methodSelected === "Paid") {
      let avlblAudncCount = this.creditLimit / (this.msgCst * this.msgCnt)
      if (this.creditLimit <= this.costForSendSms) {
        this.notifier.notify("error", "Selected Audience Limit Reached! Maximum Audience is " + avlblAudncCount)
        this.back()
        return
      }
    }
    this.next(3)
  }

  sendSms() {
    let params = {
      contactlist: this.studentListSelected,
      message: this.htmlContent,
      MsgTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      MsgType: this.methodSelected,
      Processed: 0,
      InsID: localStorage.getItem("instituteID"),
      Course: this.course,
      TeacherID: localStorage.getItem("teacherID"),
      SMSSentTo: this.AudianceType.id == "Parent" ? "P" : "S",
      isExcelFile: this.isExcelFile
    }

    console.log(params)

    this.api.sendSMS(params).subscribe(data => {
      if (data["status"] === 200) {
        console.log(data)
        this.studentListSelected = []
        this.studentListRejected = []
        this.htmlContent = null
        this.isExcelFile = false
        this.stepperState = 1
        this.stepper.to(1)
        this.notifier.notify('success', 'SMS Sent!')
      } else {
        this.notifier.notify('error', 'Send SMS Failed!')
      }
    })
  }
}
