import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-screen-wrapper',
  templateUrl: './loading-screen-wrapper.component.html',
  styleUrls: ['./loading-screen-wrapper.component.scss']
})
export class LoadingScreenWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
