import { Component, OnInit } from '@angular/core'
import { NotifierService } from 'angular-notifier'
import { apiClient } from '../../../data/apiClient'
import { Router, ActivatedRoute } from '@angular/router'

@Component({
    selector: 'app-studentdetails',
    templateUrl: './studentdetails.component.html',
    styleUrls: ['./studentdetails.component.css']
})
export class StudentdetailsComponent implements OnInit {

    private readonly notifier: NotifierService
    studentDetailsPopup: any = []
    studentCardDetailsPopup: any = []
    studentCourseDetailsPopup: any = []
    studentPaymentDetailsPopup: any = []
    studentAttendanceDetailsPopup: any = []
    student_no:any
    studentDetails = false
    parentDetails = false
    p:any

    constructor( private api: apiClient,private route: ActivatedRoute, private notifierService: NotifierService, private _router: Router) {
        
     }

    ngOnInit() {
        this.route.paramMap.subscribe(params =>{
            if(params['params'].id) {
            this.student_no = params['params'].id
            }
        })
        this.view_student()
    }

    view_student() {

        this.api.viewStudentDetails(this.student_no).subscribe(data => {

            this.studentDetailsPopup = data["data"].Student
            this.studentCardDetailsPopup = data["data"].Cards
            this.studentCourseDetailsPopup = data["data"].Courses
            this.studentPaymentDetailsPopup = data["data"].Payments
            this.studentAttendanceDetailsPopup = data["data"].Attendance
        })


    }
}
