import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { NgModule } from '@angular/core'
import { ToastrModule } from "ngx-toastr"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { OverlayModule } from '@angular/cdk/overlay';
import { AdminLayoutModule } from './layouts/admin-layout/admin-layout.module'
import { SidebarModule } from './sidebar/sidebar.module'
import { FooterModule } from './shared/footer/footer.module'
import { NavbarModule } from './shared/navbar/navbar.module'
import { FixedPluginModule } from './shared/fixedplugin/fixedplugin.module'

import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { DataTablesModule } from 'angular-datatables'

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component'
import { LoginComponent } from './pages/login/login.component'

import { NotifierModule, NotifierOptions } from 'angular-notifier'

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { JwtInterceptor, ErrorInterceptor } from './_helpers'

import { NgxEditorModule } from 'ngx-editor'
import { TooltipModule } from 'ngx-bootstrap/tooltip'
import { BrowserModule } from '@angular/platform-browser'
import { Ng2SearchPipeModule } from 'ng2-search-filter'
import { Ng2OrderModule } from 'ng2-order-pipe'
import { ChartsModule } from 'ng2-charts'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown'
import { CalendarModule } from 'angular-calendar'
import { NgxPaginationModule } from 'ngx-pagination'
import { PagerService } from './_services'
import { DatePipe, LocationStrategy, HashLocationStrategy } from '@angular/common'

import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { NgxPrintModule } from 'ngx-print'

import { ReportModule } from './pages/report/report.module'
import { ModalModule, BsDropdownModule, CollapseModule, TabsModule } from 'ngx-bootstrap';
import { ResidentListComponent } from './pages/resident-list/resident-list.component';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { LoadingScreenWrapperComponent } from './components/loading-screen-wrapper/loading-screen-wrapper.component';
import { LoadingScreenService } from './_services/loading-screen/loading-screen.service'

import { LoadingBarModule } from '@ngx-loading-bar/core'
import { LoadingInterceptor } from './_helpers/loading.interceptor';
import { MatAutocompleteModule } from '@angular/material/autocomplete'

const notifierDefaultOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'bottom',
      distance: 12,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: false,
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
}

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    ResidentListComponent,
    LoadingScreenComponent,
    LoadingScreenWrapperComponent,
  ],
  imports: [
    OverlayModule,
    BrowserModule,
    NgbModule.forRoot(),
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    TabsModule.forRoot(),
    BrowserAnimationsModule,
    AdminLayoutModule,
    AppRoutingModule,
    SidebarModule,
    NavbarModule,
    ToastrModule.forRoot(),
    NgxPrintModule,
    FooterModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    Ng2OrderModule,
    NgxPaginationModule,
    NgxEditorModule,
    ChartsModule,
    NgMultiSelectDropDownModule,
    CalendarModule,
    ReportModule,
    NotifierModule.withConfig(notifierDefaultOptions),
    TooltipModule,
    FixedPluginModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule.forRoot(),
    LoadingBarModule,
    MatAutocompleteModule,
  ],
  exports: [BsDropdownModule, TooltipModule, ModalModule, CollapseModule, TabsModule],
  providers: [
    PagerService,
    LoadingScreenService,
    DatePipe,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    LoadingScreenWrapperComponent
  ]
})
export class AppModule { }
