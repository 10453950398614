import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { apiClient } from '../../data/apiClient'
import { NotifierService } from 'angular-notifier'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { LoadingScreenService } from '../../_services/loading-screen/loading-screen.service'
import { Subject } from 'rxjs'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {

  courses: any = []
  filteredCourses: any = []
  teachers: any = []
  selectedCourse: any = {}
  model: any = {}
  grade: any = []
  add_success = false
  add_error = false
  isAdminUser: any = ''
  isSuperUser: any = ''
  addModal = 'none'
  editModal = 'none'
  public modalRef: BsModalRef
  p: any

  courseTypeDropdownSettings = {}
  courseType: any
  courseTypes: any


  private readonly notifier: NotifierService

  @ViewChild(MatPaginator) paginator: MatPaginator = null
  @ViewChild(MatSort) sort: MatSort
  displayedColumns: string[] = ['CourseID'/* , 'TeacherID' */, 'Subject', 'Grade', 'Teacher'/* , 'Fee' */, 'GroupType', 'Status', 'Options']
  dataSource: any
  filterCourses:any
  teacher:any

  constructor(private modalService: BsModalService,
    private notifierService: NotifierService, private http: HttpClient, private api: apiClient
  ) {
    this.notifier = notifierService
  }
  ngOnInit() {

    this.isAdminUser = localStorage.getItem("isAdminUser")
    this.isSuperUser = localStorage.getItem("isSuperUser")
    this.init()
    this.addGradeList()
  }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  init() {
    this.loadCourseList()
    this.loadTeacherList()

    this.courseTypeDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.courseTypes = [
      { id: "None", text: "None " },
      { id: "Meditation", text: "Meditation " },
      { id: "SEASON 8", text: "Season 8" },
      { id: "Dhamma", text: "Dhamma " },
      { id: "Card", text: "Card " },
      { id: "Donation", text: "Donation " },
      { id: "Resident", text: "Resident " }
    ]
  }

  
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  filterByTeacher(){
    this.filterCourses = []
    if(!this.teacher.match("None")){
      this.courses.filter(element => {
        if(element.Teacher.match(this.teacher)){
          this.filterCourses.push(element)
        }
      })
      this.dataSource = new MatTableDataSource(this.filterCourses)
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }else{
      this.dataSource = new MatTableDataSource(this.courses)
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
    this.dataSource.sort = this.sort
    this.dataSource.paginator = this.paginator
  }
  
  filterByProgram() {
    console.log(this.courseType)
    this.filterCourses = []
    if(this.courseType){
      this.courses.filter(element => {
        if(element.GroupType == this.courseType){
          this.filterCourses.push(element)
        }
      })
      this.dataSource = new MatTableDataSource(this.filterCourses)
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }else{
      this.dataSource = new MatTableDataSource(this.courses)
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
    this.dataSource.sort = this.sort
    this.dataSource.paginator = this.paginator
  }

  loadCourseList() {
    let params = {
      TeacherID: localStorage.getItem("teacherID"),
      instituteID: localStorage.getItem("instituteID"),
      isAdminUser: this.isAdminUser,
      isSuperUser: this.isSuperUser
    }
    this.api.getCourseList(params).subscribe((data) => {
      if (data["status"] == 200) {
        this.courses = data["data"][0] as any[];
        this.courses = data["data"][0]

        this.dataSource = new MatTableDataSource(this.courses)
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
      } else {
        this.courses = [];
      }
    })


  }


  onCourseTypeSelect(item: any) {
    this.courseType = item.id
  }

  onDeSelectCourseType(items: any) {
    this.courseType = null
  }

  loadTeacherList() {
    let params = {
      TeacherID: localStorage.getItem("teacherID"),
      instituteID: localStorage.getItem("instituteID"),
      isAdminUser: this.isAdminUser,
      isSuperUser: this.isSuperUser,
      pageNo: 1,
      size: 20


    }
    this.api.getTeachersList(params).subscribe((data) => {
      if (data["status"] == 200) {
        this.teachers = data["data"][0] as any[];
      } else {
        this.teachers = [];
      }
    })
  }



  addGradeList() {
    var num: number = 1
    for (num; num <= 12; num++) {
      this.grade.push({ "id": 1, "name": "Grade " + num });
    }
  }

  addNewCourse() {
    this.model.Insid = localStorage.getItem("instituteID")
    this.model.CourseID = "0000"
    this.model.ActiveStatus = 1
    this.model.CreatedBy = localStorage.getItem("username")
    this.model.TeachersShare = "0"
    this.api.addNewCourse(this.model).subscribe((response) => {
      if (response["status"] === 200) {
        this.init()
        this.notifier.notify("success", "New Program Successfully Added!")
      } else {
        this.notifier.notify("success", "Error!")
      }
    })
    console.log("modelllll", this.model)
  }

  editCourse() {
    this.api.editCourse(this.selectedCourse).toPromise().then((resp) => {
      if (resp["status"] === 200) {
        this.init()
        this.modalRef.hide()
        this.notifier.notify("success", "Program Successfully Edited!")
        this.selectedCourse = {}
      } else {
        this.notifier.notify("success", "Error!")
      }
    })
    console.log("selecteddddd left", this.courses)
  }

  select_course(index) {
    this.selectedCourse = {}
    this.api.selectCourse({ CourseID: index, instituteId: localStorage.getItem("instituteID") }).subscribe((resp) => {
      this.selectedCourse = resp["data"][0]
    })
  }


}
