import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
    HttpResponse,
    HttpEvent
} from '@angular/common/http';
import { finalize } from 'rxjs/operators';
//import { LoadingScreenService } from '../_services/loading-screen/loading-screen.service'
import { LoadingBarService } from '@ngx-loading-bar/core'
import { of, Observable } from 'rxjs';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];

    constructor(private loadingBar: LoadingBarService) { }

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        this.requests.splice(i, 1);
        this.loadingBar.complete();
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.requests.push(req);
        this.loadingBar.start()

        return Observable.create(observer => {
            const subscription = next.handle(req)
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse) {
                            this.removeRequest(req);
                            observer.next(event);
                        }
                    },
                    err => { this.removeRequest(req); observer.error(err); },
                    () => { this.removeRequest(req); observer.complete(); });
            return () => {
                this.removeRequest(req);
                subscription.unsubscribe();
            };
        });
    }
}