import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  _authService: any = ""

  constructor(private _router: Router) {
    localStorage.setItem("lastURL",this._router.url)
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this._authService = localStorage.getItem("isLogged")
    if (this._authService == "Yes") {
      localStorage.setItem("lastURL",this._router.url)
      return true
    }
    this._router.navigateByUrl('/login');
    return false
  }

}
