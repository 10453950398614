import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment'


interface myData {
  data: Object
}

@Injectable({
  providedIn: 'root'
})


export class apiClient {

  base_url = environment.base_url

  get_course = "/api/nisalaapi/program/get"
  get_courses = "/api/nisalaapi/program/"
  add_course = "/api/nisalaapi/program/create"
  update_course = "/api/nisalaapi/program/update"


  get_student_list = "/api/nisalaapi/member/list"
  add_student = "/api/nisalaapi/member/insert"
  get_student_serial = "/api/nisalaapi/member/serial"
  update_student_print = "/api/nisalaapi/member/print"
  update_student = "/api/nisalaapi/member/update"
  get_student_list_teacher_course = "/api/nisalaapi/member/teacher-course"
  get_user_search = "/api/nisalaapi/member/search"


  get_institute = "api/nisalaapi/institute/"
  get_institute_user = '/api/institute/user-login'
  create_institute = "/api/nisalaapi/institute/create"
  get_institute_list = "/api/nisalaapi/center/list"


  get_schedule = "/api/nisalaapi/programschedule/"
  get_schedule_list = "/api/nisalaapi/programschedule/schedule-list"
  get_schedule_list_teacher = "/api/nisalaapi/programschedule/schedule-list-teacher"
  get_schedule_list_course = "/api/schedule/schedule-list-course"
  create_schedule = "/api/nisalaapi/programschedule/create"
  update_schedule = "/api/nisalaapi/programschedule/update"
  semester_plans = "/api/schedule/semester_plan/"
  get_semester_plan = "/api/schedule/semester_plan/get"
  add_semester_plan = "/api/schedule/semester_plan/add"
  edit_semester_plan = "/api/schedule/semester_plan/edit"

  get_teacher = "/api/nisalaapi/instructor/"
  add_teacher = "/api/nisalaapi/instructor/insert"
  update_teacher = "/api/nisalaapi/instructor/update"
  get_teacher_list = "/api/nisalaapi/instructor/institute"
  courses_list_teacher = "/api/nisalaapi/instructor/courses"

  daily_attendence_list = "/api/nisalaapi/report/daily_attendence_list"
  daily_attendence_list_schedule = "/api/nisalaapi/report/daily_attendence_list_schedule"
  daily_absent_list = "/api/nisalaapi/report/daily_absent_list"
  student_grade_list = "/api/nisalaapi/report/student_grade_list"

  get_credit_limit = "/api/sms/credit-limit"
  send_sms = "/api/sms/send"



  get_resident_list = "/api/nisalaapi/residentmanage/residentlist"
  remove_resident_ist = "/api/nisalaapi/residentmanage/removeResident"

  
  attend_absent_count = "/api/school/attend_absent_count"



  constructor(public http: HttpClient) { }
  //course services

  addNewCourse(params) {
    return this.http.post(this.base_url + this.add_course, params).map(
      (res: Response) => res
    )
  }

  selectCourse(params) {
    return this.http.post(this.base_url + this.get_course, params).map(
      (res: Response) => res
    )
  }

  editCourse(params) {
    if (params.ActiveStatus) {
      params.ActiveStatus = 1
    } else {
      params.ActiveStatus = 0
    }
    return this.http.post(this.base_url + this.update_course, params).map(
      (res: Response) => res
    )
  }

  getCourseList(params) {
    console.log(params + "My params of ")
    return this.http.post(this.base_url + this.get_courses, params).map((resp: Response) => resp)
  }



  //student services

  addStudentDetails(params) {

    return this.http.post(this.base_url + this.add_student, params).map(
      (res: Response) => res
    )
  }

  UpdateStudentDetails(student) {
    return this.http.post(this.base_url + this.update_student, student).map(

      (res: Response) => res

    )
  }

  getStudentMsterData(params) {

    //return this.http.post(this.base_url + "/api/nisalaapi/member/get/", params).map(
    return this.http.post(this.base_url + this.get_student_list, params).map(


      (res: Response) => res

    )

  }

  viewStudentDetails(student_no) {

    return this.http.post(this.base_url + this.get_student_serial,
      {
        "studentSerial": student_no
      }).map(

        (res: Response) => res

      )
  }

  saveMemberFstLastName(params) {
    return this.http.post(this.base_url + this.update_student_print, params).map(

      (res: Response) => res

    )
  }

  getStudentListByTeacherCourse(params) {
    return this.http.post(this.base_url + this.get_student_list_teacher_course, params).map(

      (res: Response) => res

    )
  }



  //Institute Services

  CreateInstitute(institute) {
    return this.http.post(this.base_url + this.create_institute, institute).map(

      (res: Response) => res

    )
  }

  GetInstituteList(params) {
    return this.http.post(this.base_url + this.get_institute_list, params).map(
      (res: Response) => res
    )
  }

  GetInstituteUser(params) {
    return this.http.post(this.base_url + this.get_institute_user, params).map(

      (res: Response) => res

    )
  }


  // Schedule Services

  addSchedule(schedule) {
    return this.http.post(this.base_url + this.create_schedule, schedule).map(

      (res: Response) => res

    )
  }

  editSchedule(schedule) {
    return this.http.post(this.base_url + this.update_schedule, schedule).map(

      (res: Response) => res

    )
  }

  getSchedule(params) {
    return this.http.post(this.base_url + this.get_schedule, params).map(

      (res: Response) => res

    )
  }

  getScheduleList(params) {
    return this.http.post(this.base_url + this.get_schedule_list, params).map(

      (res: Response) => res

    )
  }

  getScheduleByCourse(params) {
    return this.http.post(this.base_url + this.get_schedule_list_course, params).map(

      (res: Response) => res

    )
  }

  
  getScheduleSemster(params) {
    return this.http.post(this.base_url + this.get_semester_plan, params).map(
      (res: Response) => res
    )
  }

  listScheduleSemster(params) {
    return this.http.post(this.base_url + this.semester_plans, params).map(
      (res: Response) => res
    )
  }

  addScheduleSemster(params) {
    return this.http.post(this.base_url + this.add_semester_plan, params).map(
      (res: Response) => res
    )
  }
  editScheduleSemster(params) {
    return this.http.post(this.base_url + this.edit_semester_plan, params).map(
      (res: Response) => res
    )
  }



  //Teacher Services

  addTeacher(params) {
    return this.http.post(this.base_url + this.add_teacher, params).map(
      (res: Response) => res
    )
  }

  editTeacher(params) {
    return this.http.post(this.base_url + this.update_teacher, params).map(
      (res: Response) => res
    )
  }

  getTeachere(params) {
    return this.http.post(this.base_url + this.get_teacher, params).map(
      (res: Response) => res
    )
  }

  getTeachersList(params) {
    return this.http.post(this.base_url + this.get_teacher_list, params).map(

      (res: Response) => res

    )
  }

  getCoursesListByTeachers(params) {

    return this.http.post(this.base_url + this.courses_list_teacher, params).map(

      (res: Response) => res

    )
  }



  //Report Services


  GetDailyAttendenceRepot(params) {
    return this.http.post(this.base_url + this.daily_attendence_list, params).map(
      (res: Response) => res
    )
  }

  GetStudentGradeList(params) {
    return this.http.post(this.base_url + this.student_grade_list, params).map(
      (res: Response) => res
    )
  }

  GetDailyAttendenceScheduleRepot(params) {
    return this.http.post(this.base_url + this.daily_attendence_list_schedule, params).map(
      (res: Response) => res
    )
  }
  GetDailyAbsentRepot(params) {
    return this.http.post(this.base_url + this.daily_absent_list, params).map(
      (res: Response) => res
    )
  }



  getCreditLimit(params) {
    return this.http.post(this.base_url + this.get_credit_limit, params).map(
      (res: Response) => res
    )
  }


  checkUserDetails(params){
    return this.http.post(this.base_url + this.get_user_search, params).map(
      (res: Response) => res
    )
  }



  sendSMS(params) {
    return this.http.post(this.base_url + this.send_sms, params).map(
      (res: Response) => res
    )
  }


  getResidebtList(params){
    return this.http.post(this.base_url + this.get_resident_list, params).map(
      (res: Response) => res
    )
  }

  removeResidebtList(params){
    return this.http.post(this.base_url + this.remove_resident_ist, params).map(
      (res: Response) => res
    )
  }

  getAttendAbsentCount(params) {
    return this.http.post(this.base_url + this.attend_absent_count, params).map(
      (res: Response) => res
    ) 
}

}
