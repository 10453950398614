import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { apiClient } from '../../data/apiClient'
import { DatePipe } from '@angular/common'
import moment from 'moment-timezone'
import { NotifierService } from 'angular-notifier'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { LoadingScreenService } from '../../_services/loading-screen/loading-screen.service'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'

@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.css']
})
export class SchedulesComponent implements OnInit {

  private readonly notifier: NotifierService
  p: any

  constructor(private modalService: BsModalService, private notifierService: NotifierService,
    private datePipe: DatePipe, private api: apiClient,
    private loadingService: LoadingScreenService) {
    this.notifier = notifierService
  }
  shedules: any = []
  filterSchedule: any = []
  courses: any = []
  teachers: any = []
  model: any = {}
  selectedShedule: any = {}
  isSuperUser: any = ""
  isAdminUser: any = ""
  public modalRef: BsModalRef
  teacher:any


  @ViewChild(MatPaginator) paginator: MatPaginator = null
  @ViewChild(MatSort) sort: MatSort 
  displayedColumns: string[] = ['Schedule','Course','Teacher','Subject','Grade','Day','StartTime','EndTime','SchDescription','Status','Options']
  dataSource: any


  ngOnInit() {
    this.isAdminUser = localStorage.getItem("isAdminUser")
    this.isSuperUser = localStorage.getItem("isSuperUser")
    this.loadTeacherList()
    this.loadScheduleList()

  }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: "model-lg" });
  }
  
  filterByTeacher(){
    this.filterSchedule = []
    if(!this.teacher.match("None")){
      this.shedules.filter(element => {
        if(element.Teacher.match(this.teacher)){
          this.filterSchedule.push(element)
        }
      })
      this.dataSource = new MatTableDataSource(this.filterSchedule)
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }else{
      this.dataSource = new MatTableDataSource(this.shedules)
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
    this.dataSource.sort = this.sort
    this.dataSource.paginator = this.paginator
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  loadScheduleList() {
    let params = {
      TeacherID: localStorage.getItem("teacherID"),
      instituteID: localStorage.getItem("instituteID"),
      isSuperUser: this.isSuperUser,
      isAdminUser: this.isAdminUser,
      pageno: 1,
      size: 30
    }
    this.api.getScheduleList(params)
      .toPromise()
      .then((data) => {
        if (data["status"] == 200) {
          this.shedules = data["data"][0] as any[];
        } else {
          this.shedules = []
        }
        this.dataSource = new MatTableDataSource(this.shedules)
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
      })
  }


  loadTeacherList() {
    let params = {
      TeacherID: localStorage.getItem("teacherID"),
      instituteID: localStorage.getItem("instituteID"),
      isAdminUser: this.isAdminUser,
      isSuperUser: this.isSuperUser,
      pageNo: 1,
      size: 30
    }

    this.api.getTeachersList(params).subscribe((data) => {
      if (data["status"] == 200) {
        this.teachers = data["data"][0] as any[];
      } else {
        this.teachers = [];
      }
    })
  }

  loadCourseListByTeachers() {
    this.courses = []
    let params = {
      teachers: [{ TeacherID: this.model.Teacher }],
      instituteID: localStorage.getItem("instituteID"),
      isAdminUser: this.isAdminUser,
      isSuperUser: this.isSuperUser
    }
    this.api.getCoursesListByTeachers(params).subscribe(resp => {
      if (resp["status"] === 200) {
        resp["data"].forEach(element => {
          element.CourseName = element.Subject + "-" + element.Grade + "-" + element.TeacherName
        })
        this.courses = resp["data"] as any[]
      }
    })
  }
  /* loadCourseList() {
    let params = {
      TeacherID: localStorage.getItem("teacherID"),
      instituteID: localStorage.getItem("instituteID"),
      isAdminUser: this.isAdminUser,
      isSuperUser: this.isSuperUser
    }

    this.api.getCourseList(params).subscribe((data) => {
      if (data["status"] == 200) {
        this.courses = data["data"][0] as any[];
      } else {
        this.courses = [];
      }
    })
  } */

  addShedule() {
    if (this.model.ActiveStatus == null) {
      return
    }
    if (this.model.ActiveStatus) {
      this.model.ActiveStatus = 1
    } else {
      this.model.ActiveStatus = 0
    }
    this.model.Ins_id = localStorage.getItem("instituteID")
    this.model.ClassSerNo = ""
    console.log(this.model)
    this.api.addSchedule(this.model)
      .toPromise().then((resp) => {
        if (resp["status"] == 200) {
          this.modalRef.hide()
          this.model = {}
          this.notifier.notify("success", "New Schedule Successfully Added!")
          this.loadScheduleList()
        } else {
          this.notifier.notify("success", "Error!")
        }
      })
  }


  editShedule() {
    if (this.selectedShedule.ActiveStatus == null) {
      return
    }
    if (this.selectedShedule.ActiveStatus) {
      this.selectedShedule.ActiveStatus = 1
    } else {
      this.selectedShedule.ActiveStatus = 0
    }

    this.selectedShedule.userId = localStorage.getItem("username")

    console.log(this.selectedShedule)

    this.api.editSchedule(this.selectedShedule)
      .toPromise().then((resp) => {
        if (resp["status"] == 200) {
          this.loadScheduleList()
          this.selectedShedule = {}
          this.modalRef.hide()
          this.notifier.notify("success", "Schedule Successfully Edited!")
        } else {
          this.notifier.notify("success", "Error!")
        }
      })
  }

  getDate(date) {
    let time = moment(date).tz("UTC").format('HH:mm:ss')
    return time
  }
  selectShedule(index) {
    console.log(index)
    this.selectedShedule = {}

    let params = {
      scheduleID: index
    }

    this.api.getSchedule(params).subscribe((resp) => {
      if (resp["status"] == 200) {
        this.selectedShedule = resp["data"][0]
        this.selectedShedule.Course = this.selectedShedule.Subject + "-" + this.selectedShedule.Grade
        this.selectedShedule.StartTime = this.getDate(this.selectedShedule.StartTime)
        this.selectedShedule.EndTime = this.getDate(this.selectedShedule.EndTime)

      }
    })
  }

}
