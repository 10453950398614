import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { apiClient } from '../../data/apiClient'
import * as moment from 'moment-timezone'
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css']
})
export class CardsComponent implements OnInit {

  private readonly notifier: NotifierService
  model: any = {}
  printFirstName: any
  printLastName: any
  public modalRef: BsModalRef
  records: any = []
  p: any
  couserDetails: any = []
  selectedStudent: any = []
  selectedStudentDetails: any = {}
  searchText: any
  Members: any
  form: FormGroup
  isAdminUser: any
  isSuperUser: any
  TeacherID: any
  line1: any
  line2: any

  @ViewChild('editStu') editStu: TemplateRef<any>


  constructor(private modalService: BsModalService,
    private notifierService: NotifierService, private api: apiClient) {
    this.notifier = notifierService
  }

  ngOnInit() {
    this.isAdminUser = localStorage.getItem("isAdminUser")
    this.isSuperUser = localStorage.getItem("isSuperUser")
    this.TeacherID = localStorage.getItem("teacherID")

    this.form = new FormGroup({
      'mask': new FormControl()
    })
  }

  public openModal(template: TemplateRef<any>, size) {
    this.modalRef = this.modalService.show(template, { class: size });
  }

  printCardName(member) {
    this.printFirstName = member.line1
    this.printLastName = member.line2 ? member.line2 : ''
  }

  addRecorde() {
    this.records.push({
      line1: this.model.line1,
      line2: this.model.line2,
      nameOnCard: (this.model.line1 ? this.model.line1 : '') + ' ' + (this.model.line2 ? this.model.line2 : ''),
      MemberCardNo: null
    })
    this.model = []
  }

  deleterecord(recordToDelete: any) {
    this.records = this.records.filter(record => record !== recordToDelete)
  }

  saveRecord(rcrd) {
    if (!rcrd.MemberCardNo) {
      this.notifier.notify('error', 'Please Fill Member Card No!')
      return
    }
    rcrd.instituteID = localStorage.getItem("instituteID")

    this.api.saveMemberFstLastName(rcrd).subscribe(data => {
      if (data["status"] == 200) {
        this.notifier.notify('success', 'Member First And Last Name Saved Success!')
        this.editStudent(rcrd)
      } else {
        this.notifier.notify('error', 'Member First And Last Name Save Failed!')
      }
    })
  }

  editStudent(student) {
    this.api.viewStudentDetails(student.MemberCardNo).subscribe(data => {
      if (data["status"] == 200) {
        this.couserDetails = data["data"].Courses;
        data["data"].Student.St_DOB = data["data"].Student.St_DOB ? moment(data["data"].Student.St_DOB).format('YYYY-MM-DD') : "1970-01-01"
        this.selectedStudent = data["data"].Student;
        this.openModal(this.editStu, 'modal-xl')
      } else {
        this.notifier.notify('error', 'Error Cant Find Member!')
      }
    })

  }

  editStudentDetails() {

    if (this.selectedStudent.sms == true) {
      this.selectedStudentDetails.Sms = 1;
    }
    else {
      this.selectedStudentDetails.Sms = 0;
    }

    if (this.selectedStudent.ActiveStatus == true) {
      this.selectedStudentDetails.ActiveStatus = 1;
    }
    else {
      this.selectedStudentDetails.ActiveStatus = 0;
    }

    if (this.selectedStudent.PaidorNot == true) {
      this.selectedStudentDetails.PaidorNot = 1;
    }
    else {
      this.selectedStudentDetails.PaidorNot = 0;
    }
    if (!this.selectedStudent.St_DOB) {
      this.selectedStudent.St_DOB = "1970-01-01"
    }
    if (!this.selectedStudent.Parent_Contact_No) {
      this.notifier.notify("error", "Enter Emergency Contact Number!")
      return
    }
    if (!this.selectedStudent.Parent_Mobile_No) {
      this.notifier.notify("error", "Enter Emergency Mobile Number!")
      return
    }
    if (!this.selectedStudent.St_Mobile_No) {
      this.notifier.notify("error", "Enter Member Mobile Number!")
      return
    }

    this.selectedStudentDetails.FirstName = this.selectedStudent.St_FristName;
    this.selectedStudentDetails.LastName = this.selectedStudent.St_LastName;
    this.selectedStudentDetails.Address = this.selectedStudent.Address;
    this.selectedStudentDetails.St_DOB = this.selectedStudent.St_DOB;
    this.selectedStudentDetails.Gender = this.selectedStudent.Gender;
    this.selectedStudentDetails.NIC = this.selectedStudent.NIC;
    this.selectedStudentDetails.FormID = this.selectedStudent.FormID;
    this.selectedStudentDetails.Email = this.selectedStudent.Email;
    this.selectedStudentDetails.Mobile = this.selectedStudent.St_Mobile_No.replace(/^0+/, '94');
    this.selectedStudentDetails.District = this.selectedStudent.District;
    this.selectedStudentDetails.School = this.selectedStudent.School;
    this.selectedStudentDetails.Remarks = this.selectedStudent.Remarks;
    this.selectedStudentDetails.ParentName = this.selectedStudent.Parent_Name;
    this.selectedStudentDetails.ParentContact = this.selectedStudent.Parent_Contact_No;
    this.selectedStudentDetails.ParentMobile = this.selectedStudent.Parent_Mobile_No.replace(/^0+/, '94');
    this.selectedStudentDetails.FathersJob = this.selectedStudent.FathersJob;
    this.selectedStudentDetails.StSerNumber = this.selectedStudent.STSerNo
    this.selectedStudentDetails.InstituteId = localStorage.getItem("instituteID");
    this.selectedStudentDetails.courses = this.couserDetails;
    this.selectedStudentDetails.UpdatedBy = localStorage.getItem('username');
    this.selectedStudentDetails.SpecialRemark = this.selectedStudent.SpecialRemark;
    this.selectedStudentDetails.title = this.selectedStudent.title;
    this.selectedStudentDetails.ocupation = this.selectedStudent.ocupation;

    this.api.UpdateStudentDetails(this.selectedStudentDetails).subscribe(data => {
      if (data["status"] == 200) {
        this.notifier.notify("success", "Member Successfully Edited!")
        this.modalRef.hide()
      } else {
        this.notifier.notify("error", "Error!")
        return
      }
    })
  }

  checkMemberDetails() {
    if (!(this.searchText.trim().length > 0)) {
      this.Members = [{ Name: "No Record Found" }]
      return
    }
    let params = {
      instituteID: localStorage.getItem("instituteID"),
      isAdminUser: this.isAdminUser,
      isSuperUser: this.isSuperUser,
      TeacherID: this.TeacherID,
      SearchText: this.searchText.trim()
    }

    this.api.checkUserDetails(params).subscribe(data => {
      if (data["status"] == 200) {
        if (data["data"][0].length > 0) {
          this.Members = data["data"][0]
        }
        else {
          this.Members = [{ Name: "No Record Found" }]
        }
      } else {
        this.Members.Name = "No Record Found"
      }
    })
  }

}
