import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  constructor(private router: Router) {
    localStorage.setItem("baseURL", "https://admin-api.wst.lk")
    if (localStorage.getItem("isLogged") !== "Yes") {
      localStorage.setItem('isLogged', 'No')
    }
  }
}
