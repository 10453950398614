import { NgModule } from '@angular/core'
import { CommonModule, DatePipe, LocationStrategy, HashLocationStrategy } from '@angular/common'

import { ReportComponent } from './report.component' 
import { DailyAttensenceComponent } from './daily-attensence/daily-attensence.component'
import { StudentListByGradeComponent } from './student-list-by-grade/student-list-by-grade.component'
import { AbsentReportComponent } from './absent-report/absent-report.component';
import { DailyAttensenceScheduleComponent } from './daily-attensence-schedule/daily-attensence-schedule.component';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown'
import { NguiDatetimePickerModule } from '@ngui/datetime-picker'
import { CalendarModule } from 'primeng/calendar'
import { Ng2SearchPipeModule } from 'ng2-search-filter'
import { Ng2OrderModule } from 'ng2-order-pipe'
import { NgxPaginationModule } from 'ngx-pagination'
import { ChartsModule } from 'ng2-charts'
import { FormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { RouterModule } from '@angular/router'

import { NgxPrintModule } from 'ngx-print';
import { StudentDetailsComponent } from './student-details/student-details.component';

@NgModule({
  declarations: [
    ReportComponent,
    DailyAttensenceComponent,
    StudentListByGradeComponent,
    AbsentReportComponent,
    DailyAttensenceScheduleComponent,
    StudentDetailsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    BrowserModule,
    Ng2SearchPipeModule, 
    Ng2OrderModule, 
    NgxPaginationModule,
    ChartsModule,
    NgxPrintModule,
    NguiDatetimePickerModule,
    NgMultiSelectDropDownModule,
    CalendarModule
  ],
  providers: [DatePipe,{provide: LocationStrategy, useClass: HashLocationStrategy}]
})
export class ReportModule { }
