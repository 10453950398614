import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { apiClient } from '../../data/apiClient';

@Component({
  selector: 'app-schools',
  templateUrl: './schools.component.html',
  styleUrls: ['./schools.component.css']
})
export class SchoolsComponent implements OnInit {
  
  data: any = []
  InstituteID: any
  private readonly notifier: NotifierService

  constructor(private notifierService: NotifierService,private api: apiClient) {
    this.notifier = notifierService
    
  }

  ngOnInit() {
    this.InstituteID = localStorage.getItem("instituteID")
    this.loadSchoolDetails()
  }

  loadSchoolDetails() {
    var count = 1;
    this.api.getAttendAbsentCount({ InstituteID: this.InstituteID }).subscribe(resp => {

      resp["data"][0].forEach(element => {
        if (!(this.data.length > 0)) {
          if (element.Grade) {
            this.data.push(
              {
                subject: element.Subject,
                grade: element.Grade.split('-')[0],
                class: []
              })
            this.data[0].class.push({
              id: count,
              Subject: element.Subject,
              class: element.Grade,
              ttl_Stu: element.TotalStudentCount,
              att_stu: element.StudentCount,
              abs_stu: element.AbsentCount
            })
          }
        } else {
          for (var i = 1; i < this.data.length + 1; i++) {
            if (element.Grade) {
              if (this.data[i - 1].grade === element.Grade.split('-')[0]) {
                count++
                this.data[i - 1].class.push({
                  id: count,
                  Subject: element.Subject,
                  class: element.Grade,
                  ttl_Stu: element.TotalStudentCount,
                  att_stu: element.StudentCount,
                  abs_stu: element.AbsentCount
                })
                return
              }
            }
          }
          for (var i = 1; i < this.data.length + 1; i++) {
            if (element.Grade) {
              if (this.data[i - 1].grade !== element.Grade.split('-')[0]) {
                count = 1
                this.data.push(
                  {
                    subject: element.Subject,
                    grade: element.Grade.split('-')[0],
                    class: []
                  })

                this.data[this.data.findIndex(dt => dt.grade === element.Grade.split('-')[0])].class.push({
                  id: count,
                  Subject: element.Subject,
                  class: element.Grade,
                  ttl_Stu: element.TotalStudentCount,
                  att_stu: element.StudentCount,
                  abs_stu: element.AbsentCount
                })
                return
              }
            }
          }
        }
      })
      this.data.sort(this.compare)
    })
  }

  compare(a, b) {
    var gradeA = a.grade
    var gradeB = b.grade
    let comparison = 0;
    if (gradeA > gradeB) {
      comparison = 1;
    } else if (gradeA < gradeB) {
      comparison = -1;
    }
    return comparison;
  }

  totalStudents(item) {
    let count = 0
    item.forEach(element => {
      count += element.ttl_Stu
    })
    return count
  }

  totalAttendents(item) {
    let count = 0
    item.forEach(element => {
      count += element.att_stu
    })
    return count
  }

  totalAbsents(item) {
    let count = 0
    item.forEach(element => {
      count += element.abs_stu
    })
    return count
  }

}
