import { Component, OnInit, TemplateRef, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { apiClient } from '../../data/apiClient'
import { NotifierService } from 'angular-notifier';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { LoadingScreenService } from '../../_services/loading-screen/loading-screen.service';
import { Subject } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-institute',
  templateUrl: './institute.component.html',
  styleUrls: ['./institute.component.css']
})
export class InstituteComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  model: any = {};
  public show: boolean = false;
  searchValue: string = '';
  p: number = 1;

  public modalRef: BsModalRef

  private readonly notifier: NotifierService


  
  @ViewChild(MatPaginator) paginator: MatPaginator = null
  @ViewChild(MatSort) sort: MatSort 
  displayedColumns: string[] = ['Ins_id', 'Name', 'Address', 'ContactNo', 'Fax', 'options']
  dataSource: any

  constructor(private modalService: BsModalService, private notifierService: NotifierService,
    private http: HttpClient, private formBuilder: FormBuilder, private api: apiClient,
    private loadingService: LoadingScreenService) {

  }

  allInstitutes = [];
  isAdminUser: any
  isSuperUser: any

  ngOnInit(): void {
    this.isAdminUser = localStorage.getItem("isAdminUser")
    this.isSuperUser = localStorage.getItem("isSuperUser")

    PageNo: Number
    size: Number
    /* 
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],

    }); */
    this.init();

  }

  init() {
    let params = {
      instituteID: localStorage.getItem("instituteID"),
      isAdminUser: this.isAdminUser,
      pageNo: 1,
      size: 15
    }

    this.api.GetInstituteList(params).subscribe(data => {
      this.allInstitutes = data["data"][0]
      this.dataSource = new MatTableDataSource(this.allInstitutes)
      this.dataSource.paginator = this.paginator
      this.dataSource.sort = this.sort
    })

  }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onSubmit() {
    if (this.model.name != "" && this.model.contactNumber != "") {
      this.api.CreateInstitute(this.model).subscribe(data => {
        if (data["status"] === 200) {
          this.init()
          this.notifier.notify("success", "New Center Successfully Added!")
        } else {
          this.notifier.notify("success", "Error!")
        }
      })
    }
    else {
      this.show = true;
    }
  }
}
