import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { PagerService } from '../../../_services/index'
import { apiClient } from '../../../data/apiClient'
import { DatePipe } from '@angular/common'
import { NotifierService } from 'angular-notifier'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { Router } from '@angular/router'
import * as moment from 'moment-timezone'
import { element } from '@angular/core/src/render3'
import { LoadingScreenService } from '../../../_services/loading-screen/loading-screen.service'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'


interface myData {
  data: Object
}

@Component({
  selector: 'app-students',
  templateUrl: './studentslist.component.html',
  styleUrls: ['./studentslist.component.css']
})
export class StudentslistComponent implements OnInit {
  record = []
  studentDetails: any = []
  studentDetailsPopup: any = []
  studentCardDetailsPopup: any = []
  studentCourseDetailsPopup: any = []
  studentPaymentDetailsPopup: any = []
  studentAttendanceDetailsPopup: any = []
  nextStudentSerial = ""
  perviousStudentSerial = ""
  student = []
  success = false
  error = false
  add_success = false
  edit_success = false
  add_error = false
  add_courses = false
  public currentCnt = 1
  display = ""
  loading: boolean
  edithedStudentSerialNo: String
  model: any = {}
  selectedStudent: any = []
  selectedStudentDetails: any = {}
  private allItems: any[]
  pager: any = []
  // paged items
  pagedItems: any[]
  allItemCount: number
  courses: any = []
  courses2: any = []
  couserDetails = []
  newCouserDetails = []

  pageSize: number

  // studentFilter = "";
  studentFilterSerial = ""
  studentFilterNicNo = ""
  studentFilterFirstName = ""
  studentFilterLastName = ""
  studentFilterMobile = ""
  studentFilterParentName = ""
  studentFilterParentMobile = ""
  studentAddress = ""
  filter = ""
  p = 0
  st_serial_no = ""
  showall:any
  public modalRef: BsModalRef

  printFirstName: any
  printLastName: any
  private readonly notifier: NotifierService

  @ViewChild(MatSort) sort: MatSort
  displayedColumns: string[] = ['img', 'STSerNo', 'Name', 'NIC', 'ocupation', 'St_Mobile_No', 'Parent_Mobile_No', 'Status', 'Options']
  dataSource: any
  length: number
  options: any
  page: number = 1
  pageOptions: any = {
    currentPage: 1,
    pageSize: 15,
    totalItems: 10,
    numberOfPages: 1,
  }
  searchText: any = ''

  constructor(private _router: Router, private modalService: BsModalService,
    private notifierService: NotifierService, private http: HttpClient,
    private pagerService: PagerService, private api: apiClient, public datepipe: DatePipe,
    private loadingService: LoadingScreenService) {
    this.notifier = notifierService
    this.options = {
      spanPages: 2,
      previousPage: true,
      nextPage: true,
      firstPage: true,
      lastPage: true,
      titles: {
        firstPage: 'First',
        previousPage: 'Previous',
        lastPage: 'Last',
        nextPage: 'Next',
        pageSize: 'Items per page'
      },
      pageSizes: [{
        value: 10,
        display: '10'
      }, {
        value: 15,
        display: '15'
      }, {
        value: 25,
        display: '25'
      }, {
        value: 50,
        display: '50'
      }, {
        value: 100,
        display: '100'
      }]
    }
  }

  ngOnInit() {
    this.showall = false
    this.init();
  }

  init() {
    this.pageSize = 15;

    this.get_student_master_data()
    this.get_course_details()

    let latest_date = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.model.PaidStartDate, this.model.JoinDate = latest_date;
    this.model.ActiveStatus = true
    this.model.sms = true
  }

  onPageEvent(event) {
    this.page = event.currentPage
    this.pageSize = event.pageSize
    this.get_student_master_data()
  }

  applyFilter(filterValue: string) {
    this.searchText = filterValue.trim().toLowerCase();
    this.page = 1
    this.get_student_master_data()
  }

  public openModal(template: TemplateRef<any>, size) {
    this.modalRef = this.modalService.show(template, { class: size });
  }

  onFilterChange(newValue) {
    this.init()
  }

  get_course_details() {
    let params = {
      TeacherID: localStorage.getItem("teacherID"),
      instituteID: localStorage.getItem("instituteID"),
      isAdminUser: localStorage.getItem("isAdminUser"),
      isSuperUser: localStorage.getItem("isSuperUser"),

    }

    this.api.getCourseList(params).subscribe(data => {

      this.courses = data["data"][0]
    })


  }

  get_student_master_data() {
    let params = {
      pageNo: this.page,
      size: this.pageSize,
      searchText: this.searchText,
      UserId: localStorage.getItem("username"),
      instituteID: localStorage.getItem("instituteID"),
      isSuperUser: localStorage.getItem("isSuperUser"),
      showall: this.showall
    }

    this.api.getStudentMsterData(params).
      subscribe(studentdetails => {
        if (studentdetails["status"] == 200) {
          this.record = studentdetails["data"][0]
          this.dataSource = new MatTableDataSource(this.record)
          this.length = studentdetails["pager"].totalStudents
          this.dataSource.sort = this.sort
          let crntPge = studentdetails["pager"].currentPage
          this.pageOptions = {
            currentPage: crntPge,
            pageSize: this.pageSize,
            totalItems: studentdetails["pager"].totalStudents,
            numberOfPages: studentdetails["pager"].totalPages,
          }
        }
      });
  }

  onCouserChange(Course_id) {
    for (var a = 0; a < this.courses.length; a++) {
      if (this.courses[a].CourseID == Course_id) {
        this.add_courses = true;
        this.couserDetails.push({
          "Subject": this.courses[a].Subject,
          "CourseID": this.courses[a].CourseID,
          "Payment": "Full Payment",
          "Grade": this.courses[a].Grade
        });

      }
      console.log();
    }
  }

  onNewCouserChange(Course_id) {
    for (var a = 0; a < this.courses.length; a++) {
      if (this.courses[a].CourseID == Course_id) {
        this.add_courses = true;
        this.newCouserDetails.push({
          "Subject": this.courses[a].Subject,
          "CourseID": this.courses[a].CourseID,
          "Payment": "Full Payment",
          "Grade": this.courses[a].Grade
        });
      }
    }
  }
  removeItem(course) {
    for (var a = 0; a < this.couserDetails.length; a++) {
      if (this.couserDetails[a].CourseID == course.CourseID) {
        this.couserDetails.splice(a, 1);
      }
    }
  }
  onPaymentChange(Course_id, selectedCourse) {
    for (var a = 0; a < this.couserDetails.length; a++) {
      if (this.couserDetails[a].CourseID == selectedCourse.CourseID) {
        this.couserDetails[a].Payment = Course_id;
      }
    }
  }

  setPage(page: number) {
    this.get_student_master_data()
  }

  public editStudent(student) {
    this.success = false;
    this.error = false;
    this.st_serial_no = student.STSerNo;
    this.add_courses = true;

    this.api.viewStudentDetails(student.STSerNo).subscribe(data => {
      this.couserDetails = data["data"].Courses;
      data["data"].Student.St_DOB = data["data"].Student.St_DOB ? moment(data["data"].Student.St_DOB).format('YYYY-MM-DD') : "1970-01-01"
      this.selectedStudent = data["data"].Student;
      this.nextStudentSerial = data["data"].NextAndPrevious[0].NextID;
      this.perviousStudentSerial = data["data"].NextAndPrevious[0].PreviousID;
    })

  }

  editStudentDetails() {

    if (this.selectedStudent.sms == true) {
      this.selectedStudentDetails.Sms = 1;
    }
    else {
      this.selectedStudentDetails.Sms = 0;
    }

    if (this.selectedStudent.ActiveStatus == true) {
      this.selectedStudentDetails.ActiveStatus = 1;
    }
    else {
      this.selectedStudentDetails.ActiveStatus = 0;
    }

    if (this.selectedStudent.PaidorNot == true) {
      this.selectedStudentDetails.PaidorNot = 1;
    }
    else {
      this.selectedStudentDetails.PaidorNot = 0;
    }

    if (!this.selectedStudent.St_DOB) {
      this.selectedStudent.St_DOB = "1900-01-01"
    }

    if (!this.selectedStudent.Parent_Contact_No) {
      this.notifier.notify("error", "Enter Emergency Contact Number!")
      return
    }
    if (!this.selectedStudent.Parent_Mobile_No) {
      this.notifier.notify("error", "Enter Emergency Mobile Number!")
      return
    }
    if (!this.selectedStudent.St_Mobile_No) {
      this.notifier.notify("error", "Enter Member Mobile Number!")
      return
    }

    this.selectedStudentDetails.FirstName = this.selectedStudent.St_FristName;
    this.selectedStudentDetails.LastName = this.selectedStudent.St_LastName;
    this.selectedStudentDetails.Address = this.selectedStudent.Address;
    this.selectedStudentDetails.St_DOB = this.selectedStudent.St_DOB;
    this.selectedStudentDetails.Gender = this.selectedStudent.Gender;
    this.selectedStudentDetails.NIC = this.selectedStudent.NIC;
    this.selectedStudentDetails.FormID = this.selectedStudent.FormID;
    this.selectedStudentDetails.Email = this.selectedStudent.Email;
    this.selectedStudentDetails.Mobile = this.selectedStudent.St_Mobile_No.replace(/^0+/, '94')
    this.selectedStudentDetails.District = this.selectedStudent.District;
    this.selectedStudentDetails.School = this.selectedStudent.School;
    this.selectedStudentDetails.Remarks = this.selectedStudent.Remarks;
    this.selectedStudentDetails.ParentName = this.selectedStudent.Parent_Name;
    this.selectedStudentDetails.ParentContact = this.selectedStudent.Parent_Contact_No;
    this.selectedStudentDetails.ParentMobile = this.selectedStudent.Parent_Mobile_No.replace(/^0+/, '94')
    this.selectedStudentDetails.FathersJob = this.selectedStudent.FathersJob;
    this.selectedStudentDetails.StSerNumber = this.selectedStudent.STSerNo
    this.selectedStudentDetails.InstituteId = localStorage.getItem("instituteID");
    this.selectedStudentDetails.courses = this.couserDetails;
    this.selectedStudentDetails.UpdatedBy = localStorage.getItem('username');
    this.selectedStudentDetails.SpecialRemark = this.selectedStudent.SpecialRemark;
    this.selectedStudentDetails.title = this.selectedStudent.title;
    this.selectedStudentDetails.ocupation = this.selectedStudent.ocupation;

    this.api.UpdateStudentDetails(this.selectedStudentDetails).subscribe(data => {
      if (data["status"] == 200) {
        this.success = true
        this.edithedStudentSerialNo = this.selectedStudentDetails.StSerNumber;
        this.init()
        this.notifier.notify("success", "Member Successfully Edited!")
        this.modalRef.hide()
      } else {
        this.notifier.notify("error", "Error!")
        this.error = true
      }

    })
    return;
  }

  editNextStudentSetails() {


    this.api.viewStudentDetails(this.nextStudentSerial).subscribe(data => {
      this.couserDetails = data["data"].Courses;
      this.selectedStudent = data["data"].Student;
      this.nextStudentSerial = data["data"].NextAndPrevious[0].NextID;
      this.perviousStudentSerial = data["data"].NextAndPrevious[0].PreviousID;
      // this.loading = true;
    })
    //let a = this.record.find(x => x.id == this.personId);

  }

  editPreviousStudentSetails() {

    this.api.viewStudentDetails(this.perviousStudentSerial).subscribe(data => {
      this.couserDetails = data["data"].Courses;
      this.selectedStudent = data["data"].Student;
      this.nextStudentSerial = data["data"].NextAndPrevious[0].NextID;
      this.perviousStudentSerial = data["data"].NextAndPrevious[0].PreviousID;
      // this.loading = true;
    })
    //let a = this.record.find(x => x.id == this.personId);

  }

  view_student(student_no) {
    this._router.navigateByUrl("home/members/" + student_no)
  }

  addStudent() {
    this.add_success = false;
    this.add_error = false;


    this.model.InstituteId = localStorage.getItem("instituteID");
    this.model.courses = this.newCouserDetails;

    this.model.CreatedBy = localStorage.getItem('username')

    if (!(this.model.courses.length > 0) || !this.model.FirstName || !this.model.LastName) {
      this.notifier.notify('error', 'Please fill required fields (*)')
      return
    }

    if (!this.model.ParentName) {
      this.model.ParentName = "-"
    }

    if (!this.model.FathersJob) {
      this.model.FathersJob = "-"
    }

    if (!this.model.ParentContact) {
      this.notifier.notify("error", "Enter Emergency Contact Number!")
      return
    }
    if (!this.model.ParentMobile) {
      this.notifier.notify("error", "Enter Emergency Mobile Number!")
      return
    }
    if (!this.model.Mobile) {
      this.notifier.notify("error", "Enter Member Mobile Number!")
      return
    }
    this.model.ParentMobile = this.model.ParentMobile.replace(/^0+/, '94')
    this.model.Mobile = this.model.Mobile.replace(/^0+/, '94')

    console.log(this.model)
    this.api.addStudentDetails(this.model).toPromise()
      .then((res) => {
        if (res["status"] == 200) {
          this.add_success = true;
          this.add_courses = true;
          this.model = {}
          this.couserDetails = []
          this.init()
          this.notifier.notify("success", "New Member Successfully Added!")
          this.modalRef.hide()
        } else {
          this.notifier.notify("error", "Error!")
          this.add_error = true;
        }
      });
  }

  printCardName(record) {
    console.log(record)
    this.printFirstName = record.St_FristName
    this.printLastName = record.St_LastName

    console.log(this.printFirstName)
  }

}
