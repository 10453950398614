import { Injectable } from '@angular/core'
import { LoadingScreenWrapperComponent } from "../../components/loading-screen-wrapper/loading-screen-wrapper.component"
import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'

@Injectable({
  providedIn: 'root'
})
export class LoadingScreenService {
  private overlayRef: OverlayRef = null

  constructor(private overlay: Overlay) { }

  show(): void {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create();
    }

    const spinnerOverlayPortal = new ComponentPortal(LoadingScreenWrapperComponent);
    const component = this.overlayRef.attach(spinnerOverlayPortal)
  }

  hide(): void {
    if (!!this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}
