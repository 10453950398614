import { Component, OnInit } from '@angular/core';


export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export const ROUTES: RouteInfo[] = [
    { path: '/home', title: 'Dashboard', icon: 'fas fa-university', class: '' },
    { path: '/home/attendence', title: 'Attendance', icon: 'fas fa-universal-access', class: '' },
    { path: '/home/center', title: 'Center', icon: 'fas fa-school', class: '' },
    { path: '/home/instructor', title: 'Instructors', icon: 'nc-badge', class: '' },
    { path: '/home/programs', title: 'Program', icon: 'fas fa-podcast', class: '' },
    { path: '/home/schedules', title: 'Schedules', icon: 'fas fa-calendar-alt', class: '' },
    { path: '/home/month-schedules', title: 'Monthly Schedules', icon: 'fas fa-calendar-alt', class: '' },
    { path: '/home/members', title: 'Members', icon: 'nc-single-02', class: '' },
    { path: '/home/sms', title: 'SMS', icon: 'fas fa-comment-alt', class: '' },
    { path: '/home/cards', title: 'Print Card', icon: 'fas fa-credit-card', class: '' },
    { path: '/home/manageResident', title: 'Manage Resident', icon: 'fas fa-home', class: '' }
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[]
    isActive:boolean 
    InsSerNo:any
    ngOnInit() {
        this.InsSerNo = localStorage.getItem("instituteID")
        this.isActive = false
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
    toggleActive(){
        this.isActive = !this.isActive
      }
}
