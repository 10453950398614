import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { NotifierModule, NotifierOptions } from 'angular-notifier'

import { AdminLayoutRoutes } from './admin-layout.routing';

import { HomeComponent } from '../../pages/home/home.component'
import { InstituteComponent } from '../../pages/institute/institute.component'
import { CardsComponent } from '../../pages/cards/cards.component'
import { CoursesComponent } from '../../pages/courses/courses.component'
import { TeachersComponent } from '../../pages/teachers/teachers.component'
import { SchedulesComponent } from '../../pages/schedules/schedules.component'
import { SchoolsComponent } from '../../pages/schools/schools.component'
import { SmsComponent } from '../../pages/sms/sms.component'
import { ReportModule } from '../../pages/report/report.module'
import { StudentsModule } from '../../pages/students/students.module'
import { YearSheduleComponent } from '../../pages/year-shedule/year-shedule.component'

import { ChartsModule } from 'ng2-charts/ng2-charts'
import { ModalModule } from 'ngx-bootstrap/modal'
import { BsDropdownModule  } from 'ngx-bootstrap/dropdown'
import { CollapseModule } from 'ngx-bootstrap/collapse'
import { TabsModule } from 'ngx-bootstrap/tabs'

import { NgxPaginationModule } from 'ngx-pagination'
import { PagerService } from '../../_services'

import { NgxEditorModule } from 'ngx-editor'
import { TooltipModule } from 'ngx-bootstrap/tooltip'
import { BrowserModule } from '@angular/platform-browser'
import { Ng2SearchPipeModule } from 'ng2-search-filter'
import { Ng2OrderModule } from 'ng2-order-pipe'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown'
import { CalendarModule, DateAdapter } from 'angular-calendar'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { NgxPrintModule } from 'ngx-print'
import { NgxMaskModule } from 'ngx-mask'

import { DataTablesModule } from 'angular-datatables'
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'
import { DlDateTimeDateModule, DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker'


import { MatTableModule } from '@angular/material/table'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSortModule } from '@angular/material/sort'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatAutocompleteModule } from '@angular/material/autocomplete'

const notifierDefaultOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'bottom',
      distance: 12,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: false,
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
}

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    RouterModule.forChild(AdminLayoutRoutes),
    NgxMaskModule.forRoot(),
    FormsModule,
    ModalModule,
    BsDropdownModule,
    CollapseModule,
    NgxPrintModule,
    TabsModule,
    ReportModule,
    StudentsModule,
    ChartsModule,
    NgxPaginationModule,
    NgxEditorModule,
    TooltipModule,
    BrowserModule,
    Ng2OrderModule,
    Ng2SearchPipeModule,
    NgMultiSelectDropDownModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    DlDateTimeDateModule,
    DlDateTimePickerModule,
    NotifierModule.withConfig(notifierDefaultOptions),
    DataTablesModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressBarModule,
    MatAutocompleteModule,
  ],
  declarations: [
    HomeComponent,
    InstituteComponent,
    CardsComponent,
    CoursesComponent,
    TeachersComponent,
    SchedulesComponent,
    SchoolsComponent,
    SmsComponent,
    YearSheduleComponent,
  ],
   providers: [
    PagerService
  ],
  exports: [BsDropdownModule, TooltipModule, ModalModule],
})

export class AdminLayoutModule {}
