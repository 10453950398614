import { Component, OnInit } from '@angular/core'
import { apiClient } from '../../../data/apiClient'
import moment from 'moment-timezone'
import { NotifierService } from 'angular-notifier'

@Component({
  selector: 'app-absent-report',
  templateUrl: './absent-report.component.html',
  styleUrls: ['./absent-report.component.css']
})
export class AbsentReportComponent implements OnInit {

  absentList: any = []
  courseList: any = []
  className: any
  isAdminUser: any
  isSuperUser: any
  startDate = ""
  endDate = ""
  course: any = []
  reportData: any = { startDate: "", endDate: "", CourseID: "", Insid: "", ProgramType: ""  }
  courseType: any
  courseTypes: any
  courseTypeDropdownSettings = {}
  courseDropdownSettings = {}

  private readonly notifier: NotifierService

  constructor(private api: apiClient, private notifierService: NotifierService) {
    this.notifier = notifierService
  }

  ngOnInit() {
    this.isAdminUser = localStorage.getItem("isAdminUser")
    this.isSuperUser = localStorage.getItem("isSuperUser")
    this.className = localStorage.getItem('instituteName')
    this.startDate = moment().tz("UTC").format('YYYY-MM-DD')
    this.endDate = moment().tz("UTC").format('YYYY-MM-DD')
    this.courseDropdownSettings = {
      singleSelection: true,
      idField: 'CourseID',
      textField: 'Course',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    }

    this.courseTypeDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.courseTypes = [
      { id: "S8", text: "Season 8" },
      { id: "None", text: "Non Season 8" },
      { id: "Resident", text: "Resident " },
      { id: "All", text: "All " }
    ]

    this.loadCourseDetails()
  }

  loadCourseDetails() {
    let params = {
      TeacherID: localStorage.getItem("teacherID"),
      instituteID: localStorage.getItem("instituteID"),
      isAdminUser: this.isAdminUser,
      isSuperUser: this.isSuperUser
    }

    this.api.getCourseList(params).subscribe((data) => {
      if (data["status"] == 200) {
        this.course = data["data"][0] as any[];
        this.course.forEach(element => {
          element.Course = element.Subject + " " + element.Grade
        })
      } else {
        this.course = [];
      }
    })
  }

  
  onCourseSelect(item: any) {
    this.courseList = []
    this.courseList.push(item)
  }

  onSelectAllCourse(items: any) {
    this.courseList = items
  }

  onDeSelectAllCourses() {
    this.courseList = []
  }

  onDeSelectCourse(items: any) {
    const index = this.courseList.findIndex(x => x.CourseID === items.CourseID);
    if (index !== undefined) this.courseList.splice(index, 1);
  }


  onCourseTypeSelect(item: any) {
    this.courseType = item.id
  }

  onDeSelectCourseType(items: any) {
    this.courseType = null
  }

  valueChangeStartDate(startDate: any) {
    this.startDate = startDate
  }

  valueChangeEndDate(endDate: any) {
    this.endDate = endDate
  }

  generateReport() {
    this.reportData.startDate = this.startDate
    this.reportData.endDate = this.endDate
    this.reportData.Insid = localStorage.getItem("instituteID")
    this.reportData.CourseID = this.courseList[0].CourseID
    this.reportData.ProgramType = this.courseType

    if (!this.reportData.CourseID) {
      this.notifier.notify('error', 'Please Select Program')
      return
    }

    if (!this.reportData.ProgramType) {
      this.notifier.notify('error', 'Please Select Program Type')
      return
    }

    this.api.GetDailyAbsentRepot(this.reportData).subscribe((resp) => {
      if (resp["status"] == 200) {
        this.absentList = resp["data"][0] as any[];
        this.absentList.forEach(element => {
          element.AttendTime = moment().tz("UTC").format('YYYY-MM-DD')
        })
      } else {
        this.absentList = [];
      }
    })
  }

  printReport() {
    window.print()
  }

}
