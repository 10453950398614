import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  OnInit
} from '@angular/core'
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
  isBefore,
  isEqual
} from 'date-fns'
import { Subject } from 'rxjs'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView
} from 'angular-calendar'

import moment from 'moment-timezone'
import { LoadingScreenService } from '../../_services/loading-screen/loading-screen.service'
import { BsModalRef, BsModalService } from 'ngx-bootstrap'
import { NotifierService } from 'angular-notifier'
import { apiClient } from '../../data/apiClient'



const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
}

@Component({
  selector: 'app-year-shedule',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './year-shedule.component.html',
  styleUrls: ['./year-shedule.component.scss']
})
export class YearSheduleComponent implements OnInit {

  @ViewChild('modalContent') modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  data: any = []

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [
    /* {
      start: subDays(startOfDay(new Date()), 1),
      end: addDays(new Date(), 1),
      title: 'A 3 day event',
      color: colors.red,
      actions: this.actions,
      allDay: true,
      resizable: {
        beforeStart: true,
        afterEnd: true
      },
      draggable: true
    },
    {
      start: startOfDay(new Date()),
      title: 'An event with no end date',
      color: colors.yellow,
      actions: this.actions
    },
    {
      start: subDays(endOfMonth(new Date()), 3),
      end: addDays(endOfMonth(new Date()), 3),
      title: 'A long event that spans 2 months',
      color: colors.blue,
      allDay: true
    },
    {
      start: addHours(startOfDay(new Date()), 2),
      end: new Date(),
      title: 'A draggable and resizable event',
      color: colors.yellow,
      actions: this.actions,
      resizable: {
        beforeStart: true,
        afterEnd: true
      },
      draggable: true
    } */
  ]

  activeDayIsOpen: boolean = true;
  private readonly loader: LoadingScreenService
  
  public modalRef: BsModalRef
  private readonly notifier: NotifierService
  model: any = {}
  selectedshedule: any = {}
  courses: any = []
  teachers: any = []
  isSuperUser: any = ""
  isAdminUser: any = ""
  year:any

  constructor(private modalService: BsModalService,private modal: NgbModal,
    private loadingService: LoadingScreenService, private api: apiClient, private notifyService:NotifierService) {
    this.loader = loadingService
    this.notifier = notifyService
  }
  ngOnInit() {
    this.isAdminUser = localStorage.getItem("isAdminUser")
    this.isSuperUser = localStorage.getItem("isSuperUser")
    this.year = moment().format("YYYY-MM-DD")
    this.loadTeacherList()
    this.loadSemesterList(this.year)
  }

  loadSemesterList(range) {
    this.events = []
    let params = {
      TeacherID: localStorage.getItem("teacherID"),
      instituteID: localStorage.getItem("instituteID"),
      isSuperUser: this.isSuperUser,
      isAdminUser: this.isAdminUser, 
      YearMonth: range
    }
     
    this.api.listScheduleSemster(params).subscribe(data => {
      if (data["status"] === 200) {
        data["data"][0].forEach(element =>{
          this.events.push({
            title: element.Subject+' '+element.Grade+' '+element.SchDescription,
            start: new Date(moment(element.StartDate).tz('UTC').format('YYYY-MM-DD HH:mm:ss')),
            end: new Date(moment(element.EndDate).tz('UTC').format('YYYY-MM-DD HH:mm:ss')),
            color: colors.red,
            draggable: false,
            allDay:false,
            meta: element,
            resizable: {
              beforeStart: true,
              afterEnd: true
            }
          })
        });
        this.refresh.next()
      }else {
        this.notifier.notify("error","Data Not loaded!")
      }
    })
  }

  loadTeacherList() {
    let params = {
      pageNo: 1,
      size: 5000,
      TeacherID: localStorage.getItem("teacherID"),
      instituteID: localStorage.getItem("instituteID"),
      isAdminUser: this.isAdminUser,
      isSuperUser: this.isSuperUser
    }

    this.api.getTeachersList(params).subscribe((data) => {
      if (data["status"] == 200) {
        this.teachers = data["data"][0] as any[];
      } else {
        this.teachers = [];
      }
    })
  }

  loadCourseListByTeachers() {
    this.courses = []
    let params = {
      teachers: [{ TeacherID: this.model.Teacher }],
      instituteID: localStorage.getItem("instituteID"),
      isAdminUser: this.isAdminUser,
      isSuperUser: this.isSuperUser
    }
    this.api.getCoursesListByTeachers(params).subscribe(resp => {
      if (resp["status"] === 200) {
        resp["data"].forEach(element => {
          element.CourseName = element.Subject + "-" + element.Grade + "-" + element.TeacherName
        })
        this.courses = resp["data"] as any[]
      }
    })
  }

  public addSemsterShedule() {

    if (!this.model.Teacher) {
      this.notifier.notify("error", "Select Teacher!")
      return
    }

    if (!this.model.CourseID) {
      this.notifier.notify("error", "Select Course!")
      return
    }

    if (!this.model.starttime) {
      this.notifier.notify("error", "Set start date time!")
      return
    }
    if (!this.model.endtime) {
      this.notifier.notify("error", "Set end date time!")
      return
    }
    if (this.model.ActiveStatus == null) {
      this.notifier.notify("error", "Make Schedule Active!")
      return
    }
    if (this.model.ActiveStatus) {
      this.model.ActiveStatus = 1
    } else {
      this.model.ActiveStatus = 0
    }
    this.model.strttime = moment(this.model.starttime).format("YYYY-MM-DD HH:mm:ss.sss")
    this.model.edtime = moment(this.model.endtime).format("YYYY-MM-DD HH:mm:ss.sss")

    
    if(isBefore(this.model.strttime,moment())){
      this.notifier.notify("error", "Set start date today or after!")
      return
    }

    if (isEqual(this.model.strttime,this.model.edtime) || isBefore(this.model.edtime,this.model.strttime)) {
      this.notifier.notify("error", "Make Sure Time Range!")
      return
    }
    this.model.Ins_id = localStorage.getItem("instituteID")
    this.model.ClassSerNo = ""
    console.log(this.model)
    this.api.addScheduleSemster(this.model)
      .toPromise().then((resp) => {
        if (resp["status"] == 200) {
          this.modalRef.hide()
          this.model = {}
          this.notifier.notify("success", "New Shedule Successfully Added!")
          this.loadSemesterList(this.year)
        } else {
          this.notifier.notify("error", "Error!")
        }
      })
  }

  public editSemsterShedule() {
    /* if(isBefore(this.selectedshedule.StartDate,moment())){
      this.notifier.notify("error", "Set start date today or after!")
      return
    } */

    if (!this.selectedshedule.StartDate) {
      this.notifier.notify("error", "Set start date time!")
      return
    }
    if (!this.selectedshedule.EndDate) {
      this.notifier.notify("error", "Set end date time!")
      return
    }
    if (this.selectedshedule.ActiveStatus == null) {
      this.notifier.notify("error", "Make Schedule Active!")
      return
    }
    if (this.selectedshedule.ActiveStatus) {
      this.selectedshedule.ActiveStatus = 1
    } else {
      this.selectedshedule.ActiveStatus = 0
    }
    this.selectedshedule.strttime = moment(this.selectedshedule.StartDate).format("YYYY-MM-DD HH:mm:ss.sss")
    this.selectedshedule.edtime = moment(this.selectedshedule.EndDate).format("YYYY-MM-DD HH:mm:ss.sss")

    if (isBefore(this.selectedshedule.edtime,this.selectedshedule.strttime) || isEqual(this.selectedshedule.edtime,this.selectedshedule.strttime)) {
      this.notifier.notify("error", "Make Sure Time Range!")
      return
    }

    this.selectedshedule.Ins_id = localStorage.getItem("instituteID")
    this.selectedshedule.ClassSerNo = ""
    this.selectedshedule.UpdatedBy = localStorage.getItem("username")
    this.api.editScheduleSemster(this.selectedshedule)
      .toPromise().then((resp) => {
        if (resp["status"] == 200) {
          this.modalRef.hide()
          this.selectedshedule = {}
          this.loadSemesterList(this.year)
          this.notifier.notify("success", "New Shedule Successfully Updated!")
        } else {
          this.notifier.notify("error", "Error!")
        }
      })
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    if (action === "Edited") {
      this.selectedshedule = event.meta
      this.selectedshedule.StartDate = new Date(moment(this.selectedshedule.StartDate).tz('UTC').format('YYYY-MM-DD HH:mm:ss'))
      this.selectedshedule.EndDate = new Date(moment(this.selectedshedule.EndDate).tz('UTC').format('YYYY-MM-DD HH:mm:ss'))
    }
    console.log(this.selectedshedule)
    this.openModal(this.modalContent);
  }

  addEvent(): void {
    this.events.push(
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        meta: this.data,
        resizable: {
          beforeStart: true,
          afterEnd: true
        }
      }
    )
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter(event => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.year = moment(this.viewDate).format("YYYY-MM-DD")
    this.loadSemesterList(this.year)
    this.activeDayIsOpen = false;
  }
  
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

}