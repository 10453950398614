import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { apiClient } from '../../data/apiClient';

@Component({
  selector: 'app-resident-list',
  templateUrl: './resident-list.component.html',
  styleUrls: ['./resident-list.component.css']
})
export class ResidentListComponent implements OnInit {

  residentList: any = []
  removeList: any = []
  p: any
  selectAll: boolean = false

  private readonly notifier: NotifierService

  constructor(private notifierService: NotifierService, private api: apiClient) {
    this.notifier = notifierService
  }

  ngOnInit() {
    this.loadResidentList()
  }

  loadResidentList() {
    let params = {
      InstituteID: localStorage.getItem("instituteID")
    }

    this.api.getResidebtList(params).subscribe(data => {
      if (data["status"] == 200) {
        this.residentList = data["data"][0]
      } else {
        this.notifier.notify('error', 'Data Not Loaded')
      }
    })
  }

  selectAllMembers() {
    if (!this.selectAll) {
      this.residentList.forEach(element => {
        element.isSelect = true
      })
    } else {
      this.residentList.forEach(element => {
        element.isSelect = false
      })
    }

  }


  removerResident() {
    this.removeList = this.residentList.filter(element => element.isSelect == true)

    if (!(this.removeList.length > 0)) {
      this.notifier.notify('error', "Select minimum one member!")
      return
    }

    let params = {
      InstituteID: localStorage.getItem('instituteID'),
      residentList: this.removeList
    }

    this.api.removeResidebtList(params).subscribe(data => {
      if (data["status"] == 200) {
        this.notifier.notify('success', "Success!")
      } else {
        this.notifier.notify('error', "Error! faild to remove.")
      }
    })
  }

}
